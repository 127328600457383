import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography"
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import { useAuth } from 'contexts/global/Auth';

import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../NavBar';

import { useState } from 'react';
import { Redirect } from 'react-router';

const DangerZone = () => {
  const { course, deleteCourse } = useCourseDetail();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 1
        }}
      >
        <Typography variant="h5">
          危險區域
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          border: '#f00 1px solid',
          borderRadius: 2,
          p: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              刪除課程
            </Typography>
            <Typography variant="subtitle2">
              刪除課程將會一併刪除所有題單列表並不可返回
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              if (window.confirm(`即將刪除 ${course.name}`)) {
                deleteCourse()
              }
            }}
          >
            刪除
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const CourseConfig = (props) => {
  const [course, setCourse] = useState(props.course);
  const [ isError, setIsError ] = useState(false);
  const { updateCourse } = useCourseDetail();
  
  const handleOnChange = (field, value) => {
    setCourse( (prev) => {
      return {
        ...prev,
        [field]: value
      }
    })
  }

  const handleOnClick = () => {
    if (
      course.name !== '' &&
      course.shortname !== ''
    )
      updateCourse(course)
    else
      setIsError(true);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 1
        }}
      >
        <Typography variant="h5">
          一般設定
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          disabled={JSON.stringify(course) === JSON.stringify(props.course)}
          onClick={handleOnClick}
        >
          儲存
        </Button>
      </Box>
      <Divider />
      <TextField
        required
        error={ isError && course.name === '' }
        size="small"
        label="名稱"
        variant="outlined"
        sx={{ mt: 4 }}
        value={course.name}
        onChange={ (e) => handleOnChange("name", e.target.value) }
      />
      <TextField
        required
        error={ isError && course.shortname === '' }
        size="small"
        label="縮寫"
        variant="outlined"
        sx={{ mt: 4 }}
        helperText="決定課程網址路徑"
        value={course.shortname}
        onChange={ (e) => handleOnChange("shortname", e.target.value) }
      />
      <TextField
        size="small"
        label="圖片網址 (選填)"
        variant="outlined"
        sx={{ mt: 4 }}
        placeholder="https://example.com"
        value={course.image}
        onChange={ (e) => handleOnChange("image", e.target.value) }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 4,
          border: 1,
          borderColor: '#555',
          p: 1,
          borderRadius: 2
        }}
      >
        <Typography variant="body1">當期課程</Typography>
        <Box sx={{ flexGrow: 1 }}/>
        <Switch
          checked={!course.history}
          onChange={ (e) => handleOnChange("history", !e.target.checked) }
        />
      </Box>
    </Box>
  )
}

const CourseSettings = () => {
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  if (!auth.profile.isAdmin)
    return <Redirect to="/403" />

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box sx={{ p: 1 }}>
        <Breadcrumbs value={[
          { label: '題單系統', link: '/' },
          { label: '課程題單', link: '/course' },
          { label: `${course.name}`, link: `/course/${course.shortname}` },
          { label: '一般設定' }
        ]}/>
        <Box sx={{
          p: 2,
          m: '0 auto',
          mt: 3,
          width: '70%',
        }}>
          <CourseConfig course={course} />
          <DangerZone course={course} />
        </Box>
      </Box>
    </NavBar>
  );
}

export default function CourseSettingsPage(props) {
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <CourseSettings />
    </CourseDetailProvider>
  )
}