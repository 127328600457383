import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TinyEditor(props) {
  const { content, setContent } = props;
  const editorRef = useRef(null);
  return (
    <Editor
      apiKey="yse19jyszeeu6n8p1mf4wtvlo27tvv09zc2xl12ylvnv6x0i"
      onInit={(evt, editor) => editorRef.current = editor}
      value={content}
      onEditorChange={(content) => setContent(content)}
      init={{
        height: "100%",
        skin: "oxide-dark",
        content_css: "dark",
        resize: false,
        plugins: [
          "advlist", "autolink", "lists", "link", "image",
          "charmap", "print", "preview", "anchor", "help",
          "searchreplace", "visualblocks", "code",
          "insertdatetime", "media", "table", "paste", "wordcount", "codesample"
        ],
        toolbar:
          "undo redo | codesample | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help"
      }}
      {...props}
    />
  );
}