export const TEACHABLE_URL = "https://www.algoseacow.com"
export const TASK_URL = `https://task${process.env.NODE_ENV !== "production" ? "-dev" : ""}.algoseacow.com`
export const JUDGE_URL = "https://judge.algoseacow.com"
export const FACEBOOK_URL = "https://www.facebook.com/algo.seacow"
export const APCSMOCK_URL = "https://apcs-mock.algoseacow.com"

export const REDIRECT_PREVIEW = {
  'https://task.algoseacow.com': '題單系統'
};

export const STATUS = ['---', 'AC', 'WA', 'TLE', 'RE'];

export const STATUS_COLOR = {
  "AC": { color: '#63C293', bgColor: '#63C293' },
  "WA": { color: '#C54944', bgColor: '#C54944' },
  "TLE": { color: '#6A9BE7', bgColor: '#6A9BE7' },
  "RE": { color: '#9B73D0', bgColor: '#9B73D0'},
}
