import { Switch, Route, withRouter } from "react-router";

import CourseListPage from 'pages/Course/List';
import NewCoursePage from 'pages/Course/New';
import CourseDetailPage from 'pages/Course/Detail';
import CourseSettingsPage from 'pages/Course/Admin/Settings';
import CourseStudentsPage from 'pages/Course/Admin/Students';
import CourseTaskEditPage from 'pages/Course/Admin/Edit';
import CourseScoreboardPage from 'pages/Course/Admin/Scoreboard';
import CourseFeedbackPage from 'pages/Course/Admin/Feedback'

import NotFoundPage from 'pages/NotFound';
import ForbiddenPage from 'pages/Forbidden';

import ConceptHomeworkPage from 'pages/exper/homework';
import HomeworkPage from 'pages/Course/Detail/Homework/Homework'
import ConceptPage from 'pages/Course/Detail/Concept/Concept'
import ProgramPage from 'pages/Course/Detail/Program/Program'

import TaskListPage from 'pages/Task'

const RouteView = () => {
  return (
    <Switch>
      <Route exact path="/" component={CourseListPage} />
      <Route exact path="/course" component={CourseListPage} />
      <Route exact path="/course/:shortname" component={CourseDetailPage} />
      <Route exact path="/course/:shortname/settings" component={CourseSettingsPage} />
      <Route exact path="/course/:shortname/students" component={CourseStudentsPage} />
      <Route exact path="/course/:shortname/edit" component={CourseTaskEditPage} />
      <Route exact path="/course/:shortname/feedback" component={CourseFeedbackPage} />
      <Route exact path="/course/:shortname/scoreboard" component={CourseScoreboardPage} />
      <Route exact path="/new_course" component={NewCoursePage} />
      <Route exact path="/403" component={ForbiddenPage} />

      <Route exact path="/exper/homework" component={ConceptHomeworkPage} />

      <Route exact path="/course/:shortname/homework/:homework_id" component={HomeworkPage} />
      <Route exact path="/course/:shortname/homework/:homework_id/concept/:concept_id" component={ConceptPage} />
      <Route exact path="/course/:shortname/homework/:homework_id/program/:program_id" component={ProgramPage} />
      
      <Route exact path="/task" component={TaskListPage}/>
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default withRouter(RouteView)