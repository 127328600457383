import { createContext, useContext, useEffect, useState } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";

const CourseListContext = createContext();

export const useCourseList = () => useContext(CourseListContext);

export default function CourseList(props) {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [ courseList, setCourseList ] = useState(null);
  const { req } = useApi();

  useEffect(() => {
    req("get", '/api/course').then( res => {
      setCourseList(res.data);
      setIsLoading(false);
    })
  }, [req])

  const addCourse = (payload) => {
    req("post", '/api/course', payload).then( res => {
      window.location.assign(`/course/${res.data.shortname}`)
    })
  }

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <CourseListContext.Provider value={{
      courseList,
      addCourse
    }}>
      { children }
    </CourseListContext.Provider>
  );
}