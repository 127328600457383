import Box from '@mui/material/Box';
import HomeworkProvider, { useHomework } from 'contexts/Homework/index.js';
import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../../NavBar';
import { useAuth } from 'contexts/global/Auth';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';

import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import { Divider, Typography } from '@mui/material';

import LaunchIcon from '@mui/icons-material/Launch'
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';

const statusProgress = (submission, score) => {
  console.log(submission)
  const taskWidth = 50;
  const taskMargin = 2;

  let config = {};
  let title = undefined;

  if (submission.feedback === undefined) {
    config = {}
    title = '批改中'
  } else {
    config = {
      variant: "determinate",
      value: submission.feedback.score * 100 / score,
      color: submission.feedback.score === score ? "success" : "error",
    }
    title = `${submission.feedback.score} / ${score}`
  }

  const read = submission.feedback === undefined || submission.feedback.read;

  return (
    <Tooltip title={title} placement="top">
      <Badge
        color="secondary"
        variant="dot"
        sx={{ display: 'flex', justifyContent: 'center' }}
        invisible={read} 
      >
        <Box 
          sx={{
            width: `${taskWidth}px`,
            m: `${taskMargin}px`,
          }}
        >
          <LinearProgress {...config} sx={{ height: 8 }} />
       </Box>
      </Badge>
    </Tooltip>
  );
}

function Homework() {
  const { homework } = useHomework();
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs value={[
            { label: '題單系統', link: '/' },
            { label: '課程題單', link: '/course' },
            { label: `${course.name}`, link: `/course/${course.shortname}` },
            { label: `${homework.title}` }
          ]}/>
        </Box>
        <Box
          sx={{
            p: 1,
            m: '0 auto',
            width: '40%',
            minWidth: '600px'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="h5" sx={{ mt: 2 }}>觀念題</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="h6">
            {
              homework.concepts.reduce( (prev, curr) => {
                if (curr.status === '-' || curr.status === '批改中')
                  return prev
                return prev + curr.status
              }, 0) 
            } / {
              homework.concepts.reduce( (prev, curr) => {
                return prev + curr.score
              }, 0) 
            }
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />
          {
            homework.concepts.map( (concept, idx) => 
              <Card raised 
                sx={{
                  mt: 1,
                }}
              >
                <CardActionArea
                  href={`/course/${course.shortname}/homework/${homework._id}/concept/${concept._id}`}
                  sx={{
                    p: 1,
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ pl: 1 }}
                    >
                      {`第 ${idx + 1} 題` + (concept.task.title ? ` - ${concept.task.title}` : '')}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography
                      variant="subtitle2"
                      sx={{ pr: 1 }}
                    >
                      {`${concept.status} / ${concept.score}`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex'}}>
                  {
                    concept.submissions.map( submission => {
                      return statusProgress(submission, concept.score)
                    })
                  }
                  </Box>
                </CardActionArea>
              </Card>
            )
          }
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="h5" sx={{ mt: 2 }}>程式題</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="h6">
            {
              homework.programs.reduce( (prev, curr) => {
                if (curr.status === '-' || curr.status === '批改中')
                  return prev
                return prev + curr.status
              }, 0) 
            } / {
              homework.programs.reduce( (prev, curr) => {
                return prev + curr.score
              }, 0) 
            }
            </Typography>
          </Box>
            <Divider sx={{ mb: 2 }} />
          {
            homework.programs.map( (program, idx) => 
              <Card raised 
                sx={{
                  mt: 1
                }}
              >
                <CardActionArea
                  href={`/course/${course.shortname}/homework/${homework._id}/program/${program._id}`}
                  sx={{
                    p: 1,
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    mb: 2
                  }}>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pl: 1 }}
                    >
                      <Link 
                        underline="none"
                        href={program.task.url}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                      >
                        {`第 ${idx + 1} 題` + (program.task.title ? ` - ${program.task.title}` : '')}
                      </Link>
                      <LaunchIcon fontSize="small" />
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ pr: 1, minWidth: '80px', ml: 2, display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {`${program.status} / ${program.score}`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex'}}>
                  {
                    program.submissions.map( submission => {
                      return statusProgress(submission, program.score)
                    })
                  }
                  </Box>
                </CardActionArea>
              </Card>
            )
          }
        </Box>
      </Box>
    </NavBar>
  )
}

export default function HomeworkPage(props) {
  const { homework_id } = props.match.params;
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <HomeworkProvider homework_id={homework_id} >
        <Homework />
      </HomeworkProvider>
    </CourseDetailProvider>
  )
}