import { createContext, useContext, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline'

const ColorModeContext = createContext();

export const useColorMode = () => useContext(ColorModeContext);

export default function Theme({ children }) {
  const [mode, setMode] = useState(
    localStorage.getItem('mode') || 'dark'
  );
  
  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#28A1C2',
      },
      secondary: red,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }
      }
    }
  });

  const toggleColorMode = () => {
    const flipColorMode = (prev) => prev === 'light' ? 'dark' : 'light';
    localStorage.setItem('mode', flipColorMode(mode));
    setMode((prev) => flipColorMode(prev));
  }

  return (
    <ColorModeContext.Provider value={{
      toggleColorMode,
      mode
    }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        { children }
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}