import Box from '@mui/material/Box';
import ClassIcon from '@mui/icons-material/Class';
import Divider from '@mui/material/Divider';

import Typography from "@mui/material/Typography"
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import { NavBarItem } from 'components/NavBar/NavBarItem/index';
import NavBarLogout from 'components/NavBar/NavBarItem/Logout';
import CourseListProvider, { useCourseList } from 'contexts/Course/List';

import { useAuth } from 'contexts/global/Auth';
import { useApi } from 'contexts/global/Api';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import { useState } from 'react';

const AddCourse = () => {
  const { addCourse } = useCourseList();
  const [course, setCourse] = useState({
    name: '',
    shortname: '',
    image: '',
  });
  
  const handleOnChange = (e, field) => {
    setCourse( (prev) => {
      return {
        ...prev,
        [field]: e.target.value
      }
    })
  }

  const [ isError, setIsError ] = useState(false);
  const handleOnClick = () => {
    if (
      course.name !== '' &&
      course.shortname !== ''
    )
      addCourse(course);
    else
      setIsError(true);
  }
  
  return (
    <Box
      sx={{
        p: 2,
        m: '0 auto',
        mt: 3,
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      <Typography variant="h6">
        新增課程
      </Typography>
      <TextField
        required
        error={ isError && course.name === '' }
        size="small"
        label="名稱"
        variant="outlined"
        sx={{ mt: 2 }}
        value={course.name}
        onChange={ (e) => handleOnChange(e, "name") }
      />
      <TextField
        required
        error={ isError && course.shortname === '' }
        size="small"
        label="縮寫"
        variant="outlined"
        sx={{ mt: 4 }}
        helperText="決定課程網址路徑"
        value={course.shortname}
        onChange={ (e) => handleOnChange(e, "shortname") }
      />
      <TextField
        size="small"
        label="圖片網址 (選填)"
        variant="outlined"
        sx={{ mt: 4 }}
        placeholder="https://example.com"
        value={course.image}
        onChange={ (e) => handleOnChange(e, "image") }
      />
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleOnClick}
        >
          確定
        </Button>
      </Box>
    </Box>
  );
}

const JoinCourse = () => {
  const query = new URLSearchParams(window.location.search);
  const [token, setToken] = useState(query.get('token') || '');
  const { req } = useApi();

  const handleOnClick = () => {
    req("post", `/api/course/student`, { token }).then(res => {
      window.location.assign(`/course/${res.data.shortname}`)
    })
  }

  return (
    <Box sx={{ p: 2, width: '70%', m: '0 auto' }}>
      <Typography variant="h6" sx={{ mt: 3 }}>
        加入課程
      </Typography>
      <TextField
        size="small"
        label="邀請碼"
        variant="outlined"
        fullWidth
        value={token}
        sx={{ mt: 3 }}
        onChange={ (e) => setToken(e.target.value) }
      />
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleOnClick}
        >
          確定
        </Button>
      </Box>
    </Box>
  );
}

const CourseNew = (props) => {
  const { auth } = useAuth();
  return (
    <NavBar
      title="題單系統"
      value={[
        <NavBarItem
          icon={<ClassIcon />}
          label='課程題單'
          href={`/course`}
        />,
        <Divider />,
        <NavBarLogout />,
      ]}
    >
      <Box sx={{ p: 1 }}>
        <Breadcrumbs value={[
          { label: '題單系統', link: '/' },
          { label: '課程題單', link: '/course' },
          { label: auth.profile.isAdmin ? '新增課程' : '加入課程' }
        ]}/>
        { auth.profile.isAdmin ? <AddCourse /> : <JoinCourse />}
      </Box>
    </NavBar>
  );
}

export default function CourseNewPage(props) {
  return(
    <CourseListProvider>
      <CourseNew />
    </CourseListProvider>
  );
}