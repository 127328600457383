import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Drawer from './Drawer';
import AppBar from './AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ColorModeController from '../ColorModeController';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../../contexts/global/Auth';

const Title = (props) => {
  return (
    <Link
      href="/"
      color="inherit"
      underline="none"
    >
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        {...props}
      >
        {props.title}
      </Typography>
    </Link>
  )
}

const Profile = () => {
  const { auth } = useAuth();
  return (
    <Box
      sx={{
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <AccountCircleIcon
        fontSize="small"
        sx={{ mr: 0.5 }}
      />
      <Typography variant="subtitle1" noWrap>
        {auth.profile.name}
      </Typography>
    </Box>
  )
}

export default function NavBar(props) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!open);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleDrawer}
            sx={{
              mr: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Title
            title={props.title}
            sx={{
              ...(open && { display: 'none' }),
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Profile />
          <ColorModeController />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <Title
            title={props.title}
            sx={{
              ...(!open && { display: 'none' }),
            }}
          />
          <IconButton
            onClick={toggleDrawer}
            sx={{ ml: '36px' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Box sx={{ overflowX: 'hidden' }}>
          <Divider />
          { props.value.map( (component, idx) => <React.Fragment key={idx}>{component}</React.Fragment> ) }
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar />
        <Box sx={{ flexGrow: 1 }}>
        { props.children }
        </Box>
      </Box>
    </Box>
  );
}