import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography"
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import TextField from '@mui/material/TextField';

import { useAuth } from 'contexts/global/Auth';
import { useDebounce } from 'utilities/hooks';

import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../NavBar';

import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  PdfFileUpload,
} from 'components/FileUpload';

import CsvImport from './CsvImport';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AddSection = () => {
  const { createSection } = useCourseDetail();
  return (
    <Card
      sx={{
        width: '100%',
        height: 200,
        mt: 2
      }}
      raised
    >
      <CardActionArea
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        onClick={ () => createSection() }
      >
        <AddIcon fontSize="large" />
        <Typography variant="h6">
          新增區塊
        </Typography>
      </CardActionArea>
    </Card>
  );
}

const SectionView = (props) => {
  const { section } = props;
  return (
    <Card
      sx={{
        width: '100%',
        mt: 2,
        p: 2,
      }}
      raised
    >
      <Typography variant="h6">
      { section.name}
      </Typography>
    </Card>
  );
}

const SectionEdit = (props) => {
  const { section, setSection } = props;
  const { deleteSection, updateSection, fetchCourse } = useCourseDetail();

  const debouncedSection = useDebounce(section, 100);
  useEffect(() => {
    updateSection(section);
  }, [debouncedSection])
  return (
    <Card
      sx={{
        width: '100%',
        mt: 2,
        borderLeft: 5,
        borderColor: (theme) => theme.palette.primary.main
      }}
      raised
    >
      <CardHeader
        title={
          <TextField
          id="outlined-basic"
          label="名稱"
          variant="outlined"
          required
          fullWidth
          value={section.name}
          onChange={ (e) => {
            setSection(prev => {
              return {
                ...prev,
                name: e.target.value
              };
            }
          )}}
        />
        }
      >
      </CardHeader>
      <CardContent>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">觀念題</Typography>
          <Button 
            variant="contained"
            color="info"
            size="small"
            sx={{
              ml: 1,
              textTransform: 'none',
              height: '30px'
            }}
            disabled={!(section.file)}
            href={`/api/file/${section.file}`}
          >
            下載題本
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <PdfFileUpload
            title="上傳題本"
            path={`/api/section/${section._id}/pdf`}
            posthook={ () => fetchCourse() }
          />
        </Box>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h6">程式題</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <CsvImport section_id={section._id}/>
        </Box>
        { section.tasks.map( task => <Box>{task.name}</Box> )}
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <IconButton
          onClick={ () => deleteSection(section._id) }
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}

const Section = (props) => {
  const { focusOn, setFocusOn } = props;
  const [section, setSection] = useState(props.section);

  useEffect(() => {
    setSection(props.section)
  }, [props.section])

  return (
    <Box
      onClick={ () =>
        setFocusOn(section._id)
      }
    >
    { focusOn ?
      <SectionEdit
        section={section}
        setSection={setSection}
      /> : 
      <SectionView
        section={section}
      />
    }
    </Box>
  )
}

const TaskEdit = () => {
  const { course, reorder } = useCourseDetail();
  const [ focusOn, setFocusOn ] = useState('');

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h5">
        題單修改
      </Typography>
      <DragDropContext
        onDragEnd={(result) => {
          console.log(course.sections.map( section => section._id ));
          const { source, destination, draggableId } = result;
          if (!destination)
            return;
          reorder(source.index, destination.index)
        }}
      >
        <Droppable droppableId="d">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
          {course.sections.map((section, idx) =>
            <Draggable draggableId={section._id} index={idx} key={section._id}>
              {p => (
                <div
                  ref={p.innerRef}
                  {...p.draggableProps}
                  {...p.dragHandleProps}
                >
                  <Section
                    section={section}
                    focusOn={focusOn === section._id || (focusOn === '' && idx === 0)}
                    setFocusOn={setFocusOn}
                  />
                </div>
              )}
            </Draggable>
          )}
          </div>
        )}
        </Droppable>
      </DragDropContext>
      <AddSection />
    </Box>
  );
}

const CourseTaskEdit = () => {
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  if (!auth.profile.isAdmin)
    return <Redirect to="/403" />

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box sx={{ p: 1 }}>
        <Breadcrumbs value={[
          { label: '題單系統', link: '/' },
          { label: '課程題單', link: '/course' },
          { label: `${course.name}`, link: `/course/${course.shortname}` },
          { label: '題單修改' }
        ]}/>
        <Box sx={{
          p: 2,
          m: '0 auto',
          mt: 3,
          width: '70%',
        }}>
          <TaskEdit />
        </Box>
      </Box>
    </NavBar>
  );
}

export default function CourseSettingsPage(props) {
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <CourseTaskEdit />
    </CourseDetailProvider>
  )
}
