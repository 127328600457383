import Box from '@mui/material/Box';
import ConceptProvider, { useConcept } from 'contexts/Concept/index.js';
import HomeworkProvider, { useHomework } from 'contexts/Homework/index.js';
import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../../NavBar';
import { useAuth } from 'contexts/global/Auth';
import Editor from 'components/Editor';
import Tooltip from '@mui/material/Tooltip';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Badge from '@mui/material/Badge';

import MathJax from 'react-mathjax3'
import Alert from '@mui/material/Alert';

import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import { Button, Divider, Typography } from '@mui/material';

import { useState } from 'react';

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Switch from '@mui/material/Switch';

import LockIcon from '@mui/icons-material/Lock';

function mathjax(html) {
  return (
    <MathJax.Context
        input='tex'
        onLoad={ () => console.log("Loaded MathJax script!") }
        onError={ (MathJax, error) => {
            console.warn(error);
            console.log("Encountered a MathJax error, re-attempting a typeset!");
            MathJax.Hub.Queue(
              MathJax.Hub.Typeset()
            );
        } }
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js"
        options={ {
            messageStyle: 'none',
            extensions: ['tex2jax.js'],
            jax: ['input/TeX', 'output/HTML-CSS'],
            tex2jax: {
                inlineMath: [['$', '$'], ['\\(', '\\)']],
                displayMath: [['$$', '$$'], ['\\[', '\\]']],
                processEscapes: true,
            },
            TeX: {
                extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
            }
        } }
    >
        <MathJax.Html html={ html } />
    </MathJax.Context>
);
}

const SubmissionList = () => {
  const {
    conceptSubmissions,
    deleteSubmission,
    viewSubmissionId ,
    changeViewSubmissionId,
    resetViewSubmissionId
  } = useConcept();

  if (conceptSubmissions.length === 0) {
    return <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>尚未有提交紀錄</Box>
  }

  return (
    <List component="nav" sx={{
      width: '100%'
    }}>
      {
        conceptSubmissions.map( (submission, idx) => {
          const selected = (viewSubmissionId === submission._id);
          const read = submission.feedback === undefined || submission.feedback.read
          return (
            <ListItem key={idx} selected={selected}>
              <Badge
                color="secondary"
                variant="dot"
                sx={{ display: 'flex', justifyContent: 'center' }}
                invisible={read} 
              >
                <Box>{submission.createdAt}</Box>
              </Badge>
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                {
                  submission.feedback ? 
                  `${submission.feedback.score} / ${submission.task.score}`
                  : `批改中`
                }
                </Box>
                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-begin' }}>
                { submission.feedback && submission.feedback.content ? 
                  <Tooltip title={submission.feedback ? submission.feedback.content : ''} placement="top">
                    <FeedbackIcon /> 
                  </Tooltip>
                  : ''
                }
                </Box>
              </Box>
              { selected ? 
                  <IconButton onClick={() => resetViewSubmissionId()}><VisibilityOffIcon /></IconButton>
                  :
                  <IconButton onClick={ () => changeViewSubmissionId(submission._id)}><VisibilityIcon /></IconButton>
              } 
              <IconButton disabled={submission.feedback} onClick={() => deleteSubmission(submission._id)}><DeleteIcon /></IconButton>
            </ListItem>
          );
        })
      }
    </List>
  );
}

function Concept() {
  const { concept, createConceptSubmission, content, setContent,
    viewSubmissionId,
    viewContent,
    setViewContent,
    updateConceptSubmission,
    unlockAnswer
  } = useConcept();
  const { homework } = useHomework();
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  const [isPreview, setIsPreview] = useState(false);

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box
        sx={{
          p: 1,
          pb: 0,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs value={[
            { label: '題單系統', link: '/' },
            { label: '課程題單', link: '/course' },
            { label: `${course.name}`, link: `/course/${course.shortname}` },
            { label: `${homework.title}`, link: `/course/${course.shortname}/homework/${homework._id}` },
            { label: concept.pid }
          ]}/>
        </Box>
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            height: '0px',
          }}
        >
          <Box sx={{ p: 2, overflow: 'auto', width: '50%' }}>
            <Typography variant="h5">提交紀錄</Typography>
            <Divider />
            <SubmissionList />
            <Typography variant="h5">題目敘述</Typography>
            <Divider sx={{ mb: 2 }} />
            <Alert severity="info">皆需要附上解題過程，否則不予計分</Alert>
            <Typography variant="subtitle1">{mathjax(concept.content)}</Typography>
            { concept.has_answer || auth.profile.isAdmin ? 
            <>
              <Typography variant="h5">題目解答</Typography>
              <Divider />
              { concept.answer !== undefined ? mathjax(concept.answer) :
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<LockIcon />}
                    onClick={ () => {
                      if (window.confirm('將會失去該題的提交和修改功能, 確定要解鎖嗎')) {
                        unlockAnswer();
                      }
                    }}
                  >
                    解鎖
                  </Button>
                </Box> }
            </> : ''
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>預覽</Typography>
              <Switch checked={isPreview} onChange={(e) => setIsPreview(e.target.checked)} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: 'flex', p: 1 }}>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button variant="contained" onClick={
                  viewSubmissionId ? 
                  () => updateConceptSubmission({ content: viewContent })
                  :
                  () => createConceptSubmission({ content })
                }>{ viewSubmissionId ? "修改" : "送出"}</Button>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              {
                viewSubmissionId ? 
                  isPreview ? <Box>{mathjax(viewContent)}</Box> : 
                  <Editor content={viewContent} setContent={setViewContent}/>
                  :
                  isPreview ? <Box>{mathjax(content)}</Box> : 
                  <Editor content={content} setContent={setContent}/>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </NavBar>
  )
}

export default function ConceptPage(props) {
  const { shortname, homework_id, concept_id } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <HomeworkProvider homework_id={homework_id} >
        <ConceptProvider concept_id={concept_id} >
          <Concept />
        </ConceptProvider>
      </HomeworkProvider>
    </CourseDetailProvider>
  )
}