import { createContext, useEffect, useContext, useState, useCallback } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";
import { useSnackbar } from "notistack";

const CourseFeedbackContext = createContext();

export const useCourseFeedback = () => useContext(CourseFeedbackContext);

export default function CourseFeedback(props) {
  const { children, shortname } = props;
  const { req } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [submissions, setSubmissions] = useState([]);

  const fetchSubmissions = useCallback((payload) => {
    req("get", `/api/course/${shortname}/submission`, payload).then( res => {
      setSubmissions(res.data);
      setIsLoading(false);
    }, () => {
      // window.location.assign('/403');
    })
  }, [req, shortname])

  useEffect(() => {
    fetchSubmissions()
  }, [fetchSubmissions])

  const { enqueueSnackbar } = useSnackbar();

  const postConceptFeedback = (submission_id, payload) => {
    req("post", `/api/concept_task_submission/${submission_id}/feedback`, payload).then( res => {
      setSubmissions((prev) => {
        return { ...prev,
          concept: prev.concept.map( submission => 
            submission._id !== submission_id ? 
              submission :
              { ...submission, feedback: res.data }
          )
        }
      })
      enqueueSnackbar(`更新成功`, { variant: 'success' });
    }).catch( () => enqueueSnackbar(`更新失敗`, { variant: 'error' }) )
  }

  const postProgramFeedback = (submission_id, payload) => {
    req("post", `/api/program_task_submission/${submission_id}/feedback`, payload).then( res => {
      setSubmissions((prev) => {
        return { ...prev,
          program: prev.program.map( submission => 
            submission._id !== submission_id ? 
              submission :
              { ...submission, feedback: res.data }
          )
        }
      })
      enqueueSnackbar(`更新成功`, { variant: 'success' });
    }).catch( () => enqueueSnackbar(`更新失敗`, { variant: 'error' }) )
  }

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <CourseFeedbackContext.Provider value={{
      submissions,
      postConceptFeedback,
      postProgramFeedback
    }}>
      { children }
    </CourseFeedbackContext.Provider>
  );
}