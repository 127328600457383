import { useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import { useAuth } from 'contexts/global/Auth';
import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../NavBar';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Overview from './Overview';
import OverviewPerSection from './OverviewPerSection';
import Section from './Section';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import HomeworkList from './Homework/HomeworkList';

const UserIdSelector = () => {
  const { course, view, fetchCourse, setView } = useCourseDetail();
  const { auth } = useAuth();

  return (
    <TextField
      id="standard-select-currency"
      select
      value={view === '' ? JSON.stringify(auth.profile) : view}
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <RemoveRedEyeIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        setView(e.target.value)
        fetchCourse({ user_id: JSON.parse(e.target.value)._id })
      }}
    >
      <MenuItem
        key={auth.profile._id}
        value={JSON.stringify(auth.profile)}
      >
        {auth.profile.name}
      </MenuItem>
      <Divider />
      {course.students.map((option) => (
        <MenuItem 
          key={option._id}
          value={JSON.stringify(option)}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

const CourseDetail = () => {
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs value={[
            { label: '題單系統', link: '/' },
            { label: '課程題單', link: '/course' },
            { label: `${course.name}` }
          ]}/>
          {/* { auth.profile.isAdmin && <UserIdSelector /> } */}
        </Box>
        { 
          course.homeworks.length ?
            <HomeworkList /> : 
            <Box
              sx={{
                p: 1,
                m: '0 auto',
                width: '95%',
                maxWidth: '1000px',
                display: 'flex',
                flexGrow: 1,
                height: '0px'
              }}
            >
              <Hidden mdDown>
                <Grid
                  item
                  md={5}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Paper sx={{ mr: 1, mb: 1 }}>
                    <Overview />
                  </Paper>
                  <Paper
                    sx={{
                      height: '0px',
                      flexGrow: 1,
                      overflow: 'auto',
                      mr: 1,
                      mt: 1
                    }}
                  >
                    <OverviewPerSection />
                  </Paper>
                </Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  overflow: 'auto',
                }}
              >
                <Box sx={{ p: 0, flexGrow: 1  }}>{
                  course.sections.map( (section, idx) => 
                    <Section
                      key={idx}
                      section={section}
                      id={`section-${idx}`}
                    />
                  )
                }</Box>
              </Grid>
            </Box>
        }
      </Box>
    </NavBar>
  );
}

export default function CourseDetailPage(props) {
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <CourseDetail />
    </CourseDetailProvider>
  )
}