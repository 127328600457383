import React from "react";
import Box from '@mui/material/Box';

import NavBar from 'components/NavBar';
import { CourseDetailNavBar } from '../../NavBar';

import { useAuth } from 'contexts/global/Auth';
import Breadcrumbs from 'components/Breadcrumbs';
import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import CourseScoreboardProvider, { useCourseScoreboard } from 'contexts/Course/Scoreboard';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinearProgress from '@mui/material/LinearProgress';
import TerminalIcon from '@mui/icons-material/Terminal';

const CourseScoreboardHeader = ({ nameWidth, taskWidth, taskMargin }) => {
  const { course } = useCourseDetail();
  return (
    <Box sx={{ display: 'flex', mt: 2 }}>
      <Box sx={{ minWidth: `${nameWidth + taskMargin - 1}px` }} />
      {
        course.homeworks.map( homework => 
          <Box key={homework._id}>
            <Box sx={{ m: '0 auto', display: 'flex', justifyContent: 'center', width: `${(homework.concepts.length + homework.programs.length + 2 * taskMargin) * taskWidth}px` }}>{homework.title}</Box>
            <Box sx={{ display: 'flex', border: '1px solid grey'  }}>
            {
              homework.concepts.map( (concept) => 
                <IconButton key={concept._id} sx={{ width: `${taskWidth}px`, ml: `${taskMargin}px`, mr: `${taskMargin}px` }} component={Link}
                  to={`/course/${course.shortname}/homework/${homework._id}/concept/${concept._id}`}
                ><AssignmentIcon fontSize="small" /></IconButton> 
              )
            }
            {
              homework.programs.map( (program) =>
                <IconButton key={program._id} sx={{ width: `${taskWidth}px`, ml: `${taskMargin}px`, mr: `${taskMargin}px` }} component={Link}
                  to={`/course/${course.shortname}/homework/${homework._id}/program/${program._id}`}
                ><TerminalIcon fontSize="small" /></IconButton>
              )
            }
            </Box>
          </Box>
        )
      }
    </Box>
  );
}

const CourseScoreboardBody = ({ nameWidth, taskWidth, taskMargin }) => {
  const { scoreboard } = useCourseScoreboard();

  return (
  <Box>
    {
      scoreboard.map( user => 
        <>
          <Box key={user._id} sx={{ display: 'flex' }}>
            <Box sx={{ minWidth: `${nameWidth}px`, border: '1px solid grey' }}>{user.name}</Box>
            <Box sx={{ display: 'flex'}}>
            {
              user.scoreboard.map( homework => {
                return (
                  <Box sx={{ height: '100%', display: 'flex', border: '1px solid grey', alignItems: 'center' }}>
                  {
                    homework.map( task => {
                    if (task.status === '-')
                      return <Box 
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: `${taskWidth}px`,
                          m: `${taskMargin}px`,
                        }}/>
                    
                    if (task.status === '批改中') {
                      return (
                        <Box sx={{ width: `${taskWidth}px`, m: `${taskMargin}px` }}>
                          <LinearProgress />
                        </Box>
                      )
                    }

                    return (
                      <Box sx={{ width: `${taskWidth}px`, m: `${taskMargin}px` }}>
                        <LinearProgress
                          variant="determinate"
                          value={task.status * 100 / task.score}
                          color={ task.status === task.score ? "success" : "error" }
                        />
                      </Box>
                    )
                    })
                  }
                  </Box>
                )
              })
            }
            </Box>
          </Box>
        </>
      )
    }
    </Box>
  )
}

const CourseScoreboard = () => {
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  const nameWidth = 200;
  const taskWidth = 16;
  const taskMargin = 1;

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box sx={{ p: 1, whiteSpace: 'nowrap' }}>
        <Breadcrumbs value={[
          { label: '題單系統', link: '/' },
          { label: '課程題單', link: '/course' },
          { label: `${course.name}`, link: `/course/${course.shortname}` },
          { label: '分數統計' }
        ]}/>
        <CourseScoreboardHeader taskMargin={taskMargin} nameWidth={nameWidth} taskWidth={taskWidth} />
        <CourseScoreboardBody taskMargin={taskMargin} nameWidth={nameWidth} taskWidth={taskWidth} />
      </Box>
    </NavBar>
  )
}

export default function CourseScoreboardPage(props) {
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <CourseScoreboardProvider shortname={shortname}>
        <CourseScoreboard />
      </CourseScoreboardProvider>
    </CourseDetailProvider>
  )
}