import Box from '@mui/material/Box';
import ProgramProvider, { useProgram } from 'contexts/Program/index.js';
import HomeworkProvider, { useHomework } from 'contexts/Homework/index.js';
import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../../NavBar';
import { useAuth } from 'contexts/global/Auth';
import Editor from "@monaco-editor/react";
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip';
import FeedbackIcon from '@mui/icons-material/Feedback';
import Badge from '@mui/material/Badge';

import MenuItem from '@mui/material/MenuItem';

import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import { Button, Divider, Typography } from '@mui/material';

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';

import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { STATUS_COLOR, STATUS } from 'utilities/const';

import LockIcon from '@mui/icons-material/Lock';
import mathjax from 'utilities/mathjax';

const SubmissionList = () => {
  const {
    programSubmissions,
    deleteSubmission,
    viewSubmissionId ,
    changeViewSubmissionId,
    resetViewSubmissionId
  } = useProgram();

  if (programSubmissions.length === 0) {
    return <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>尚未有提交紀錄</Box>
  }

  return (
    <List component="nav" sx={{
      width: '100%'
    }}>
      {
        programSubmissions.map( (submission, idx) => {
          const selected = (viewSubmissionId === submission._id);
          const read = submission.feedback === undefined || submission.feedback.read
          return (
            <ListItem sx={{ display: 'flex' }} key={idx} selected={selected}>
              <Badge
                color="secondary"
                variant="dot"
                sx={{ display: 'flex', justifyContent: 'center' }}
                invisible={read} 
              >
                <Box>{submission.createdAt}</Box>
              </Badge>
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                {
                  submission.feedback ? 
                  `${submission.feedback.score} / ${submission.task.score}`
                  : `批改中`
                }
                </Box>
                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-begin' }}>
                { submission.feedback && submission.feedback.content ? 
                  <Tooltip title={submission.feedback ? submission.feedback.content : ''} placement="top">
                    <FeedbackIcon /> 
                  </Tooltip>
                  : ''
                }
                </Box>
              </Box>
              { selected ? 
                  <IconButton onClick={() => resetViewSubmissionId()}><VisibilityOffIcon /></IconButton>
                  :
                  <IconButton onClick={ () => changeViewSubmissionId(submission._id)}><VisibilityIcon /></IconButton>
              } 
              <IconButton disabled={submission.feedback}  onClick={() => deleteSubmission(submission._id)}><DeleteIcon /></IconButton>
            </ListItem>
          );
        })
      }
    </List>
  );
}

function Program() {
  const { program, createProgramSubmission, content, setContent,
    viewSubmissionId,
    viewContent,
    setViewContent,
    updateProgramSubmission,
    unlockAnswer
  } = useProgram();
  const { homework } = useHomework();
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  const showContent =  viewSubmissionId ? viewContent : content;
  const setShowContent = viewSubmissionId ? setViewContent : setContent;

  const theme = useTheme();
  
  const STATUS_COLOR_WITH_EMPTY = {
    ...STATUS_COLOR,
    "---": {
      color: theme.palette.text.primary,
      bgColor: theme.palette.mode === 'dark' ? 
        theme.palette.grey['A700'] :
        theme.palette.grey['A400']
    },
  }

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box
        sx={{
          p: 1,
          pb: 0,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumbs value={[
            { label: '題單系統', link: '/' },
            { label: '課程題單', link: '/course' },
            { label: `${course.name}`, link: `/course/${course.shortname}` },
            { label: `${homework.title}`, link: `/course/${course.shortname}/homework/${homework._id}` },
            { label: program.title }
          ]}/>
        </Box>
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            height: '0px',
          }}
        >
          <Box sx={{ flexGrow: 1, p: 2, overflow: 'auto' }}>
            <Typography variant="h5">提交紀錄</Typography>
            <Divider />
            <SubmissionList />
            { program.has_answer || auth.profile.isAdmin ?
            <>
              <Typography variant="h5">題目解答</Typography>
              <Divider />
              { program.answer !== undefined ? mathjax(program.answer) :
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<LockIcon />}
                    onClick={ () => {
                      if (window.confirm('將會失去該題的提交和修改功能, 確定要解鎖嗎')) {
                        unlockAnswer();
                      }
                    }}
                  >
                    解鎖
                  </Button>
                </Box>
              }
            </> : '' }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography noWrap variant="h6" sx={{ display: 'flex',alignItems: 'center', flexGrow: 1 }}>
                <Link 
                  underline="none"
                  href={program.url}
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                >
                  {program.title}
                </Link>
                <LaunchIcon />
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: 'flex', p: 1 }}>
                <Box sx={{ flexGrow: 1 }}></Box>
                <TextField
                  id="standard-select-currency"
                  select
                  value={showContent.status}
                  onChange={(e) => 
                    setShowContent((prev) => {
                      return {
                        ...prev, status: e.target.value
                      }
                    })
                  }
                  variant="standard"
                  sx={{ width: '70px' }}
                  InputProps={{
                    style: { color: STATUS_COLOR_WITH_EMPTY[showContent.status].color, margin: '0px auto', padding: '0 5px' }
                  }}
                >
                  {STATUS.map((option) => (
                    <MenuItem 
                      key={option}
                      value={option}
                      sx={{ color: STATUS_COLOR_WITH_EMPTY[option].color, justifyContent: 'center' }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <Button variant="contained" onClick={
                  viewSubmissionId ? 
                  () => updateProgramSubmission({ content: showContent })
                  :
                  () => createProgramSubmission({ content })
                }>{ viewSubmissionId ? "修改" : "送出"}</Button>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              {
                <Editor
                  height="100%"
                  width="100%"
                  fontSize="30px"
                  defaultLanguage="cpp"
                  value={showContent.code}
                  theme='vs-dark'
                  onChange={
                    (code) => setShowContent(prev => {
                      return {
                        code, status: prev.status
                      }
                    })
                  }
                />
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </NavBar>
  )
}

export default function ProgramPage(props) {
  const { shortname, homework_id, program_id } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <HomeworkProvider homework_id={homework_id} >
        <ProgramProvider program_id={program_id} >
          <Program />
        </ProgramProvider>
      </HomeworkProvider>
    </CourseDetailProvider>
  )
}