import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import GlobalProvider from './contexts/global';

import RouteView from './RouteView'
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min'

import CacheBuster from 'react-cache-buster';

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <GlobalProvider>
        <BrowserRouter>
          <RouteView />
        </BrowserRouter>
      </GlobalProvider>
    </CacheBuster>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
