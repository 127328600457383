import { createContext, useEffect, useContext, useState, useCallback } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";
import { useSnackbar } from "notistack";

const HomeworkContext = createContext();

export const useHomework = () => useContext(HomeworkContext);

export default function Homework(props) {
  const { children, homework_id } = props;
  const { req } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [homework, setHomework] = useState(null);

  const fetchHomework = useCallback((payload) => {
    req("get", `/api/homework/${homework_id}`, payload).then( res => {
      setHomework(res.data);
      setIsLoading(false);
    }, () => {
      window.location.assign('/403');
    })
  }, [req, homework_id])

  useEffect(() => {
    fetchHomework()
  }, [fetchHomework])

  const { enqueueSnackbar } = useSnackbar();
  /*
  const updateCourse = (payload) => {
    req("put", `/api/course/${course.shortname}`, payload).then( res => {
      const { name, shortname, image } = res.data;
      setHowework((prev) => { return {
        ...prev,
        name,
        shortname,
        image
      }});
      enqueueSnackbar(`更新成功`, { variant: 'success' });
    })
  }

  const deleteCourse = () => {
    req("delete", `/api/course/${course.shortname}`).then( res => {
      enqueueSnackbar(`刪除課程成功`, { variant: 'success' });
      window.location.assign('/course');
    })
  }

  const createSection = () => {
    req("post", `/api/course/${course.shortname}/section`, {}).then( res => {
      fetchCourse()
    })
  }

  const deleteSection = (_id) => {
    req("delete", `/api/course/${course.shortname}/section/${_id}`).then( res => {
      enqueueSnackbar(`刪除區塊成功`, { variant: 'success' });
      fetchCourse();
    })
  }

  const updateSection = (payload) => {
    req("put", `/api/section/${payload._id}`, payload).then( res => {
    })
  }

  const importSectionTasks = (payload) => {
    const { content } = payload;
    req("put", `/api/section/${payload.section_id}/csv`, { content })
    .then( res => {
      enqueueSnackbar('匯入 csv 成功', { variant: 'success' })
      fetchCourse();
    })
  }

  const submit = (payload) => {
    req("post", `/api/submission`, payload)
    .then( () => {
      enqueueSnackbar('更改狀態成功', { variant: 'success' })
      fetchCourse();
    })
  }

  const reorder = (from, to) => {
    if (Math.min(from, to) < 0 || Math.max(from, to) >= course.sections.length || from == to)
      return ;
    
    let sections = course.sections.map( section => section._id );
    const [remove] = sections.splice(from, 1);
    sections.splice(to, 0, remove);
      
    req("put", `/api/course/${course.shortname}/section`, { sections })
    .then( () => {
      enqueueSnackbar('替換順序成功', { variant: 'success' })
      fetchCourse();
    })
  }
  */

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <HomeworkContext.Provider value={{
      homework
    }}>
      { children }
    </HomeworkContext.Provider>
  );
}