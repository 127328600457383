import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { STATUS_COLOR } from 'utilities/const';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import { useState } from 'react';
import { useApi } from 'contexts/global/Api';
import { useCourseDetail } from 'contexts/Course/Detail';
import { useEffect } from 'react';
import Loading from 'components/Loading';
import Editor from "@monaco-editor/react";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import LaunchIcon from '@mui/icons-material/Launch';
import { useSnackbar } from "notistack";
import { useAuth } from 'contexts/global/Auth';

function ChangeTaskStatusModal(props) {
  const { open, setOpen, task, STATUS_COLOR_WITH_EMPTY } = props;
  const handleClose = () => setOpen(false);

  const [status, setStatus] = useState(task.status);
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { submit, view } = useCourseDetail();
  const { req } = useApi();
  const { auth } = useAuth();

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      req("get", `/api/submission/${task._id}`, {
        user_id: view !== '' ? JSON.parse(view)._id : ''
      })
      .then( res => {
        setCode(res.data.code);
        setStatus(res.data.status);
      })
      .catch( err => {
        setCode('');
        setStatus(task.status);
        console.log(err);
      })
      .finally( () => {
        setIsLoading(false);
      })
    }
  }, [open])

  const STATUS = ['---', 'AC', 'WA', 'TLE', 'RE'];

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const self_view = view === '' || JSON.parse(view)._id === auth.profile._id;

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      {
        isLoading ?
          <Box sx={{ width: '100%', height: '100%' }}>
            <Loading />
          </Box>
        :
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '600px',
            width: '90%',
            height: '70%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: '20px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ display: 'flex',}}>
            <Typography noWrap variant="h6" sx={{ display: 'flex',alignItems: 'center', flexGrow: 1 }}>
            <Link 
              underline="none"
              href={task.link}
              target="_blank"
              rel="noopener"
              color="inherit"
            >
                {task.name}
            </Link>
            <LaunchIcon />
            </Typography>
            <TextField
              id="standard-select-currency"
              select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="standard"
              sx={{ width: '70px' }}
              InputProps={{
                style: { color: STATUS_COLOR_WITH_EMPTY[status].color, margin: '0px auto', padding: '0 5px' }
              }}
              disabled={!self_view}
            >
              {STATUS.map((option) => (
                <MenuItem 
                  key={option}
                  value={option}
                  sx={{ color: STATUS_COLOR_WITH_EMPTY[option].color, justifyContent: 'center' }}
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ flexGrow: 1, m: '10px 0px' }}>
            <Editor
              height="100%"
              width="100%"
              defaultLanguage="cpp"
              defaultValue={code}
              theme='vs-dark'
              onChange={(code) => setCode(code) }
              options={{ readOnly: !self_view }}
            />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button variant="text" onClick={handleClose}>取消</Button>
            <Box sx={{ flexGrow: 1 }}/>
            <Button variant="contained"
              disabled={!self_view}
              onClick={() => {
                if (status !== '---' && code === '') {
                  enqueueSnackbar('請提供程式碼', { variant: 'error' })
                  return ;
                }
                if (status === '---' && code !== '') {
                  enqueueSnackbar('請填寫程式碼狀態', {variant: 'error' })
                  return ;
                }
                if (code.length > 65535) {
                  enqueueSnackbar('程式碼長度不得超過 65535', { variant: 'error' })
                  return ;
                }

                submit({
                  taskId: task._id,
                  code, status
                })
                setOpen(false);
              }}
            >
              上傳
            </Button>
          </Box>
        </Box>
      }
    </Modal>
  );
}

function Task(props) {
  const theme = useTheme();
  const STATUS_COLOR_WITH_EMPTY = {
    ...STATUS_COLOR,
    "---": {
      color: theme.palette.text.primary,
      bgColor: theme.palette.mode === 'dark' ? 
        theme.palette.grey['A700'] :
        theme.palette.grey['A400']
    },
  }

  const { task } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => { setOpen(true); }

  return ( 
    <>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          '&:hover': {
            background: theme.palette.mode === 'dark' ? "#333" : '#eee',
          }
        }}
        onClick={handleOpen}
      >
        <Typography
          variant="subtitle2" 
          sx={{
            flexGrow: 1,
          }}
        >
          <Link 
            underline="none"
            href={task.url}
            target="_blank"
            rel="noopener"
            onClick={ (e) => e.stopPropagation() }
            color="inherit"
          >
          {task.name}
          </Link>
        </Typography>
        <Typography
          variant="subtitle2" 
          sx={{
            color: STATUS_COLOR_WITH_EMPTY[task.status].color,
            display: 'flex',
            justifyContent: 'center',
            width: '50px'
          }}
        >
          {task.status}
        </Typography>
      </Box>
      <Divider />
      <ChangeTaskStatusModal task={task} open={open} setOpen={setOpen} STATUS_COLOR_WITH_EMPTY={STATUS_COLOR_WITH_EMPTY} />
    </>
  );
}

export default function Section(props) {

  const { section } = props;

  return (
    <Box
      id={props.id}
      sx={{
        mb: 1,
        p: 2
      }}
    >
      <Typography variant="h5">
        {props.section.name}
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: 3,
        }}
      >
        <Divider />
        { section.tasks.map((task, idx) =>  <Task key={idx} task={task}/> ) }
        <Divider />
      </Paper>
    </Box>
  );
}