import { useRef } from "react";
import { Button } from "@mui/material"
import { useSnackbar } from "notistack";
import { useApi } from "contexts/global/Api";
import { useCourseDetail } from "contexts/Course/Detail";


export default function CsvImport(props) {
  const fileRef = useRef();
  const { req } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const { importSectionTasks } = useCourseDetail();

  const read = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) reject('no file selected!')
      const reader = new window.FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error);
    })
  }
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    read(file).then( content => 
      importSectionTasks({
        content, section_id: props.section_id
      })
    )
    e.target.value=''
  }
  return (
    <Button
      variant="contained"
      color="info"
      component="label"
      sx={{
        textTransform: 'none',
        height: '30px'
      }}
      size="small"
    >
      匯入 csv
      <input
        type="file"
        hidden
        type='file'
        accept=".csv"
        ref={fileRef}
        onChange={handleFileChange}
      />
    </Button>
  )
}