import Box from '@mui/material/Box';
import { useAuth } from 'contexts/global/Auth';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useCourseDetail } from 'contexts/Course/Detail';

import { STATUS_COLOR } from "utilities/const";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import useTheme from '@mui/material/styles/useTheme';

function LinearProgressBox(props) {
  const { entry, total } = props;
  const [height, setHeight] = useState(`${props.height}px`);

  const theme = useTheme();
  const STATUS_COLOR_WITH_EMPTY = {
    ...STATUS_COLOR,
    "---": {
      color: theme.palette.text.primary,
      bgColor: theme.palette.mode === 'dark' ? 
        theme.palette.grey['A700'] :
        theme.palette.grey['A400']
    },
  }

  return (
    <Tooltip title={`${entry.name} ${entry.value}`} placement="top" arrow>
      <Box
        sx={{
          height: height,
          flexGrow: total === 0 && entry.name === '---' ? 1 : entry.value,
          background: STATUS_COLOR_WITH_EMPTY[entry.name].bgColor,
          transition: 'width 100ms ease-in-out'
        }}
        onMouseOver={ () => setHeight(props.height + 2)}
        onMouseOut={ () => setHeight(props.height) }
      />
    </Tooltip>
  );
}

function LinearProgress(props) {

  const { overview } = props;
  const height = props.height || 4;

  const data = [
    { name: 'AC', value: overview.AC },
    { name: 'WA', value: overview.WA },
    { name: 'TLE', value: overview.TLE },
    { name: 'RE', value: overview.RE },
    { name: '---', value: overview['---'] }
  ];

  const total = data.reduce((prev, curr) => prev + curr.value, 0)
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: height + 10,
    }}>{
      data.map((entry, idx) => 
        <LinearProgressBox 
          key={idx}
          entry={entry}
          height={height}
          total={total}
        />
      )
    }</Box>
  );
}

function SectionOverview(props) {
  const { section } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
          p: 1,
          '&:hover': {
            background: theme.palette.mode === 'dark' ? 
              "#333" : '#eee'
          }
      }}
    >
      <Typography noWrap variant="subtitle1">
        {section.name}
      </Typography>
      <LinearProgress overview={section.overview}/>
    </Box>
  );
}

export default function OverviewPerSection(props) {
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  const _id = props.profile_id || auth.profile._id;

  return <Box sx={{ p: 1 }}>{
    course.sections.map( (section, idx) =>
      <Link
        key={idx}
        href={`#section-${idx}`}
        underline="none"
        color="inherit"
      >
        <SectionOverview section={section} />
      </Link>
    )
  }</Box>
}
