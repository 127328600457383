import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import MathJax from 'react-mathjax3'
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';

function TinyEditor({content, setContent}) {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      const text = JSON.stringify(editorRef.current.getContent());
      navigator.clipboard.writeText(text).then( () => console.log("success!") )
      console.log(text);
    }
  };
  return (
    <>
      <Editor
        apiKey="yse19jyszeeu6n8p1mf4wtvlo27tvv09zc2xl12ylvnv6x0i"
        onInit={(evt, editor) => editorRef.current = editor}
        value={content}
        onEditorChange={(content) => setContent(content)}
        init={{
          height: 500,
          skin: "oxide-dark",
          content_css: "dark",
          plugins: [
            "advlist", "autolink", "lists", "link", "image",
            "charmap", "print", "preview", "anchor", "help",
            "searchreplace", "visualblocks", "code",
            "insertdatetime", "media", "table", "paste", "wordcount", "codesample"
          ],
          toolbar:
            "undo redo | codesample | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help"
        }}
      />
      <button onClick={log}>Log editor content</button>
    </>
  );
}

function mathjax(html) {
  return (
    <MathJax.Context
        input='tex'
        onLoad={ () => console.log("Loaded MathJax script!") }
        onError={ (MathJax, error) => {
            console.warn(error);
            console.log("Encountered a MathJax error, re-attempting a typeset!");
            MathJax.Hub.Queue(
              MathJax.Hub.Typeset()
            );
        } }
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js"
        options={ {
            messageStyle: 'none',
            extensions: ['tex2jax.js'],
            jax: ['input/TeX', 'output/HTML-CSS'],
            tex2jax: {
                inlineMath: [['$', '$'], ['\\(', '\\)']],
                displayMath: [['$$', '$$'], ['\\[', '\\]']],
                processEscapes: true,
            },
            TeX: {
                extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
            }
        } }
    >
        <MathJax.Html html={ html } />
    </MathJax.Context>
);
}

const problems = [
  {
    title: '第一題',
    score: 30,
    content: "<p>可以插入程式碼</p>\n<pre class=\"language-cpp\"><code>#include&lt;bits/stdc++.h&gt;\nusing namespace std;\nbool isp(long long x) {\n    for (int i = 2 ; i &lt;= sqrt(x) ; i++) {\n        if (x % i == 0) {\n            return 0;\n        }\n    }\n    return 1;\n}\nint main() {\n    for (int i = 1000000000 ; ;i++)\n        if (isp(i)) {\n             cout &lt;&lt; i &lt;&lt; '\\n';\n             break;\n        }\n    }\n    for (int i = 1000000000 ; ;i--)\n        if (isp(i)) {\n             cout &lt;&lt; i &lt;&lt; '\\n';\n             break;\n        }\n    }\n}</code></pre>\n<p>&nbsp;</p>\n<p>可以放圖片</p>\n<p><img src=\"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOFote0zSY88A0RZn-JwsoupPh3yiM52bkZySKmfV0XQ&amp;s\" alt=\"演算法海牛- Home | Facebook\"><br><br>可以寫 mathjax $\\sum\\limits_{i=1}^{10} i^2$</p>"
  }
]

export default function App() {
  return (
    <Box>{
      problems.map((problem, idx) => <Problem problem={problem} />)
    }</Box>
  );
}


function Problem({ problem }) {
  const [answer, setAnswer] = useState('Write down your answer here....');
  console.log(problem.content)
  return (
    <Box sx={{ m: '0 auto', width: '60%', mt: 10, mb: 10}}>
      <Box>
        <Typography variant="h6" component="h6">{problem.title}</Typography>
      </Box>
      <Box sx={{ p: 1 }}>
        {problem.content && mathjax(problem.content)}
      </Box>
      <Divider />
      <TinyEditor content={answer} setContent={setAnswer}/>

      <Box sx={{ p: 1 }}>
        {answer && mathjax(answer)  }
      </Box>
    </Box>
  )
}
