import { Button } from "@mui/material"
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from 'react';
import { useApi } from "contexts/global/Api";
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress } from "@mui/material";

export const FileUpload = (props) => {
  const { status, setStatus } = props;
  const title = props.title || '上傳'
  const fileRef = useRef();
  useEffect(() => {
    if (status === 'success' || status === 'error') {
      setTimeout(() => {
        setStatus('init');
      }, 3000)
    }
  }, [status])
  
  return (
    <Button
      variant="contained"
      color={
        status === 'success' ? 'success' : 
        status === 'error' ? 'error' : 'info'
      }
      component="label"
      sx={{
        textTransform: 'none',
        height: '30px'
      }}
      size="small"
    >
      {
        status === 'success' ? <CheckIcon /> :
        status === 'uploading' ? <CircularProgress size={20}/> :
        status === 'error' ? <ErrorIcon /> :
        status === "init" ? title : ''     
      }
      <input
        type="file"
        hidden
        type='file'
        accept={props.accept}
        ref={fileRef}
        onChange={props.handleFileChange}
      />
    </Button>
  )
}

export const PdfFileUpload = (props) => {
  const { req } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState('init');
  
  const base64Encode = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) reject('no file selected!')
      const reader = new window.FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    base64Encode(file)
      .then( content => {
        setStatus('uploading');
        req("post", props.path, { name: file.name, content })
          .then( res => {
            setStatus('success');
            enqueueSnackbar('上傳檔案成功', { variant: 'success' })
            if (props.posthook)
              props.posthook()
          }, () => {
            setStatus('error');
          })
      })
    e.target.value=''
  }
  return (
    <FileUpload
      {...props}
      handleFileChange={handleFileChange}
      accept=".pdf"
      status={status}
      setStatus={setStatus}
    />
  )
}