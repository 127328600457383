import BreadcrumbsBase from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

export default function Breadcrumbs(props) {
  return (
    <BreadcrumbsBase
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      {...props}
    >{
      props.value.map( (obj, idx) => 
        obj.hasOwnProperty('link') ?
          <Link
            underline="hover"
            key={idx}
            color="inherit"
            component={RouterLink}
            to={obj.link}
          >
            {obj.label}
          </Link> :
          <Typography
            key={idx}
            color="text.primary"
          >
            {obj.label}
          </Typography>
      )
    }</BreadcrumbsBase>
  );
};