import { useCourseDetail } from 'contexts/Course/Detail';
import { PieChart, Pie, Cell, Sector } from 'recharts';
import { STATUS_COLOR } from 'utilities/const';
import { useState } from 'react';
import { useAuth } from 'contexts/global/Auth';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={-5} textAnchor="middle" fill={payload.color}>
        {payload.name}
      </text>
      <text x={cx} y={cy} dy={+15} textAnchor="middle" fill={payload.color}>
        {payload.value}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.bgColor}
      />
      <Sector
        cornerRadius={2}
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius - 3}
        outerRadius={innerRadius + 1}
        fill={payload.bgColor}
      />
      <Sector
        cornerRadius={2}
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius - 1}
        outerRadius={outerRadius + 3}
        fill={payload.bgColor}
      />
    </g>
  );
}

const OverviewPieChart= () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const theme = useTheme();

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieOut = () => {
    setActiveIndex(-1);
  }

  const { course } = useCourseDetail();
  const { overview } = course;
  const percent = Math.ceil(overview.AC * 100 / overview.total) || 0;

  const STATUS_COLOR_WITH_EMPTY = {
    ...STATUS_COLOR,
    "---": {
      color: theme.palette.text.primary,
      bgColor: theme.palette.mode === 'dark' ? 
        theme.palette.grey['A700'] :
        theme.palette.grey['A400']
    },
  }

  const data = [
    { name: 'AC', value: overview.AC },
    { name: 'WA', value: overview.WA },
    { name: 'TLE', value: overview.TLE },
    { name: 'RE', value: overview.RE },
    { name: '---', value: overview['---'] }
  ].map( payload => { return {
    ...payload,
    color: STATUS_COLOR_WITH_EMPTY[payload.name].color,
    bgColor: STATUS_COLOR_WITH_EMPTY[payload.name].bgColor
  }})

  return (
    <PieChart
      height={140}
      width={140}
    >
      <text
        x={70}
        y={70}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={theme.palette.text.primary}
      >
        {activeIndex === -1 ? `${percent}%` : ''}
      </text>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        innerRadius={40}
        outerRadius={50}
        dataKey="value"
        onMouseOver={onPieEnter}
        onMouseOut={onPieOut}
        startAngle={90}
        endAngle={90 - 360}
      >{
        data.map( (entry, idx) => 
          <Cell key={idx} fill={STATUS_COLOR_WITH_EMPTY[entry.name].bgColor} stroke="none" />
        )
      }</Pie>
    </PieChart>
  );
}

export default function Overview() {
  const { auth } = useAuth();
  const { course, view } = useCourseDetail();
  const { profile } = auth;
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="div" >
          { view === '' ? profile.name : JSON.parse(view).name }
        </Typography>
        <Typography variant="subtitle1" component="div" >
          { course.name }
        </Typography>
      </Box>
      <OverviewPieChart />
    </Box>
  );
}