import IconButton from '@mui/material/IconButton';
import { useColorMode } from "contexts/global/Theme";

import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';

export default function ColorModeController() {
  const { mode, toggleColorMode } = useColorMode();

  return (
    <IconButton
      onClick={toggleColorMode}
      color="inherit"
      size="small"
    >
      { 
        mode === 'light' ? 
          <Brightness7Icon /> :
          <Brightness4Icon />
      }
    </IconButton>
  )
}