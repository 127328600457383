import { Link } from 'react-router-dom';

import { useCourseDetail } from 'contexts/Course/Detail';

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

const statusProgress = (prefix, task, idx) => {
  const taskWidth = 70;
  const taskMargin = 2;

  let config = {};
  let title = undefined;

  if (task.status === '-') {
    config = {
      color: "inherit",
      variant: "determinate",
      value: 0 
    }
    title = "尚未提交"
  } else if (task.status === '批改中') {
    config = {}
    title = task.status
  } else {
    config = {
      variant: "determinate",
      value: task.status * 100 / task.score,
      color: task.status === task.score ? "success" : "error",
    }
    title = `${task.status} / ${task.score}`
  }

  return (
    <Tooltip title={title} placement="top">

      <Box 
        sx={{
          width: `${taskWidth}px`,
          m: `${taskMargin}px`,
        }}
      >
        <CardActionArea sx={{ p: 1 }}
          href={`${prefix}/${task._id}`}
        >
          <Badge
            color="secondary"
            variant="dot"
            sx={{ display: 'flex', justifyContent: 'center' }}
            invisible={task.read} 
          >
            <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center' }}>
              第 {idx + 1} 題
            </Typography>
          </Badge>
            <LinearProgress {...config} sx={{ height: 8 }} />
        </CardActionArea>
      </Box>

    </Tooltip>
  );
}

const Homework = ({ homework }) => {
  const { course } = useCourseDetail();
  console.log(homework)
  return (
    <Card sx={{ minWidth: '400px', width: '60%', m: '0 auto', mt: 2 }} raised>
      <CardActionArea
        href={`/course/${course.shortname}/homework/${homework._id}`}
      >
        <CardHeader title={homework.title} />
      </CardActionArea>
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="subtitle1" component="div">
          觀念題
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
        {
          homework.concepts.map( (concept, idx) =>
            statusProgress(`/course/${course.shortname}/homework/${homework._id}/concept`, concept, idx)
          )
        }
        </Box>
        <Typography variant="subtitle1" component="div">
          程式題
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {
          homework.programs.map( (program, idx) => 
            statusProgress(`/course/${course.shortname}/homework/${homework._id}/program`, program, idx) )
        }
        </Box>
      </CardContent>

    </Card>
  );
}

export default function HomeworkList(props) {
  const { course } = useCourseDetail();

  return course.homeworks.map( (homework, idx) => <Homework key={idx} homework={homework} /> )
}