import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography"
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import Switch from '@mui/material/Switch';
import Pagination from '@mui/material/Pagination';
import Editor from "@monaco-editor/react";
import { STATUS_COLOR } from 'utilities/const';

import Card from '@mui/material/Card';
import { useAuth } from 'contexts/global/Auth';

import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import CourseFeedbackProvider, { useCourseFeedback } from 'contexts/Course/Feedback';
import { CourseDetailNavBar } from '../../NavBar';

import mathjax from 'utilities/mathjax';

import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import TextField from '@mui/material/TextField';
import { useSnackbar } from "notistack";

import LaunchIcon from '@mui/icons-material/Launch'
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const ProgramSubmission = ({ submission }) => {
  const { postProgramFeedback } = useCourseFeedback();
  const [score, setScore] = useState(submission.feedback ? submission.feedback.score : '');
  const [content, setContent] = useState(submission.feedback ? submission.feedback.content : '');

  const { enqueueSnackbar } = useSnackbar();
  useEffect( () => {
    setScore(submission.feedback ? submission.feedback.score : '')
    setContent(submission.feedback ? submission.feedback.content : '')
  }, [submission])

  const timestring = new Date(submission.updatedAt).toLocaleString()

  return (
    <Card sx={{ mt: 2, p: 2 }} raised>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">題目敘述</Typography>
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pl: 1 }}
        >
          <Link 
            underline="none"
            href={submission.task.task.url}
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            {submission.task.task.title}
          </Link>
          <LaunchIcon fontSize="small" />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h6">{timestring}</Typography>
      </Box>
      <Divider sx={{ m: 1 }} />
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h5">學生回答</Typography>
        <Typography variant="h5" sx={{ ml: 1, color: STATUS_COLOR[submission.status] }}>{submission.status}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h6">{submission.user.name}</Typography>
      </Box>
      <Divider sx={{ m: 1 }} />
      <Box sx={{ flexGrow: 1, m: '10px 0px', height: 400 }}>
        <Editor
          height="100%"
          width="100%"
          defaultLanguage="cpp"
          value={submission.code}
          theme='vs-dark'
          options={{ readOnly: true}}
        />
      </Box>
      <Divider sx={{ m: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField 
          InputLabelProps={{
            shrink: true,
          }}
          value={score}
          onChange={ (e) => setScore(e.target.value) }
          required
          label="分數"
          sx={{ width: 100 }} variant="outlined" size="small" />
        <Typography sx={{ m: 1 }}>/ {submission.task.score}</Typography>
        <TextField 
          InputLabelProps={{
            shrink: true,
          }}
          value={content}
          onChange={ (e) => setContent(e.target.value) }
          sx={{ flexGrow: 1, ml: 1 }}
          label="備註(選填)"
          variant="outlined" size="small" />
        <Button
          variant="contained" sx={{ ml: 1 }}
          color={ submission.feedback ? "success" : "error" }
          onClick={ () => {
            if (0 <= score && score <= submission.task.score)
              postProgramFeedback(submission._id, { score, content }) 
            else {
              enqueueSnackbar(`分數介於 0 到 ${submission.task.score} 的整數`, { variant: 'error' })
            }
          }}
        >送出</Button>
      </Box>
    </Card>
  );
}

const ConceptSubmission = ({ submission }) => {
  const { postConceptFeedback } = useCourseFeedback();
  const [score, setScore] = useState(submission.feedback ? submission.feedback.score : '');
  const [content, setContent] = useState(submission.feedback ? submission.feedback.content : '');

  const { enqueueSnackbar } = useSnackbar();

  useEffect( () => {
    setScore(submission.feedback ? submission.feedback.score : '')
    setContent(submission.feedback ? submission.feedback.content : '')
  }, [submission])

  const timestring = new Date(submission.updatedAt).toLocaleString()

  return (
    <Card sx={{ mt: 2, p: 2 }} raised>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">
          {submission.task.task.pid}
          {submission.task.task.title ? ` - ${submission.task.task.title}` : ''}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h6">{timestring}</Typography>
      </Box>
      <Divider sx={{ m: 1 }} />
      {mathjax(submission.task.task.content)}
      <Divider sx={{ m: 1 }} />
      <Typography variant="h5">題目解答</Typography>
      <Divider sx={{ m: 1 }} />
      {mathjax(submission.task.task.answer)}
      <Divider sx={{ m: 1 }} />
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h5">學生回答</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h6">{submission.user.name}</Typography>
      </Box>
      <Divider sx={{ m: 1 }} />
      {mathjax(submission.content)}
      <Divider sx={{ m: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField 
          InputLabelProps={{
            shrink: true,
          }}
          value={score}
          onChange={ (e) => setScore(e.target.value) }
          required
          label="分數"
          sx={{ width: 100 }} variant="outlined" size="small" />
        <Typography sx={{ m: 1 }}>/ {submission.task.score}</Typography>
        <TextField 
          InputLabelProps={{
            shrink: true,
          }}
          value={content}
          onChange={ (e) => setContent(e.target.value) }
          sx={{ flexGrow: 1, ml: 1 }}
          label="備註(選填)"
          variant="outlined" size="small" />
        <Button
          variant="contained" sx={{ ml: 1 }}
          color={ submission.feedback ? "success" : "error" }
          onClick={ () => {
            if (0 <= score && score <= submission.task.score)
              postConceptFeedback(submission._id, { score, content }) 
            else {
              enqueueSnackbar(`分數介於 0 到 ${submission.task.score} 的整數`, { variant: 'error' })
            }
          }}
        >送出</Button>
      </Box>
    </Card>
  );
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const CourseFeedback = () => {
  const { course } = useCourseDetail();
  const { submissions } = useCourseFeedback();
  const { auth } = useAuth();

  const perPage = 5;
  const [page, setPage] = useState(1);

  const [noFeedback, setNoFeedback] = useState(true)
  const [isConcept, setIsConcept] = useState(true);
  const [search, setSearch] = useState('');

  const concept_submission = submissions.concept.filter(
    concept => !noFeedback || concept.feedback === undefined
  ).sort( (a, b) => a.task.task.pid.localeCompare(b.task.task.pid));
  const filtered_concept_submission = concept_submission
    .filter( (submission) => 
      submission.task.task.pid.match(escapeRegExp(search)) ||
      submission.user.name.match(escapeRegExp(search))
    )
  const sliced_concept_submission = filtered_concept_submission
    .slice(perPage * (page - 1),
          Math.min(filtered_concept_submission.length, perPage * page));

  const program_submission = submissions.program.filter(
    program => !noFeedback || program.feedback === undefined
  ).sort( (a, b) => a.task.task.url.localeCompare(b.task.task.url));
  const filtered_program_submission = program_submission
    .filter( (submission) => 
      submission.task.task.title.match(escapeRegExp(search)) ||
      submission.user.name.match(escapeRegExp(search))
    )
  const sliced_program_submission = filtered_program_submission
    .slice(perPage * (page - 1),
          Math.min(filtered_program_submission.length, perPage * page));
    
  if (!auth.profile.isAdmin)
    return <Redirect to="/403" />

  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box sx={{ p: 1 }}>
        <Breadcrumbs value={[
          { label: '題單系統', link: '/' },
          { label: '課程題單', link: '/course' },
          { label: `${course.name}`, link: `/course/${course.shortname}` },
          { label: '作業批改' }
        ]}/>
        <Box sx={{
          m: '0 auto',
          mt: 3,
          width: '90%',
          minWidth: '900px'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              size="small"
              variant="standard"
              value={search}
              onChange={ (e) => {
                setSearch(e.target.value)
                setPage(1)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Switch checked={noFeedback} onChange={ (e) => {
              setNoFeedback(e.target.checked)
              setPage(1);
            }} />
            <Typography>尚未批改</Typography>
            <Box sx={{ ml: 5 }} />

            <Typography>程式題</Typography>
            <Switch checked={isConcept} onChange={ (e) => {
              setIsConcept(e.target.checked) 
              setPage(1)
            }} />
            <Typography>觀念題</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Pagination 
              page={page}
              onChange={(e, v) => setPage(v)}
              count={Math.ceil((isConcept ? filtered_concept_submission : filtered_program_submission).length / perPage)} />
          </Box>
          
          
          { isConcept ? 
            sliced_concept_submission.map( submission => 
              <ConceptSubmission submission={submission}/>
            )
            :
            sliced_program_submission.map( submission => 
              <ProgramSubmission submission={submission}/>
            )
          }
        </Box>
      </Box>
    </NavBar>
  );
}

export default function CourseFeedbackPage(props) {
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <CourseFeedbackProvider shortname={shortname}>
        <CourseFeedback />
      </CourseFeedbackProvider>
    </CourseDetailProvider>
  )
}
