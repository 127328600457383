import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';

import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { alpha } from '@mui/material/styles';

import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';

import { useAuth } from 'contexts/global/Auth';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CheckIcon from '@mui/icons-material/Check';

import CourseDetailProvider, { useCourseDetail } from 'contexts/Course/Detail';
import { CourseDetailNavBar } from '../../NavBar';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Modal from '@mui/material/Modal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useApi } from 'contexts/global/Api';
import { TASK_URL } from 'utilities/const';
import Loading from 'components/Loading';

import { useSnackbar } from "notistack";
import { Redirect } from 'react-router';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: '姓名',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: '信箱',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } =
    props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const InviteModal = (props) => {
  const { open, setOpen } = props;
  const handleClose = () => {
    setOpen(false);
    setToken(null);
  }
  const { req } = useApi();
  const { course } = useCourseDetail();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // if (open) {
      req("post", `/api/course/${course.shortname}/student`, {}).then( res => {
        setToken(res.data.token);
        setIsLoading(false);
      })
    // }
  }, [req, course.shortname])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const CopyButton = ({ text }) => {
    const [isCopy, setIsCopy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleOnClick = () => {
      if (isCopy)
        return ;
      navigator.clipboard.writeText(text).then(
        () => {
          enqueueSnackbar('複製成功', { variant: 'success' })
          setIsCopy(true);
          setTimeout(() => setIsCopy(false), 2000)
        },
        () => {
          enqueueSnackbar('複製失敗', { variant: 'success' })
        }
      )
    }
    return (
      <IconButton onClick={handleOnClick}>
      { isCopy ? <CheckIcon color="success" /> : <ContentCopyIcon /> }
      </IconButton>
    )
  }
  
  const inviteUrl = `${TASK_URL}/new_course?token=${token}`;
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
      {isLoading ? 
        <Loading /> : 
        <>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            邀請碼
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
            <TextField
              size="small" 
              id="outlined-basic"
              variant="outlined"
              defaultValue={token}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flexGrow: 1 }}
            />
            <CopyButton text={token}/>
          </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            邀請連結
          </Typography>
          <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
            <TextField 
              size="small" 
              id="outlined-basic"
              variant="outlined"
              defaultValue={inviteUrl}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flexGrow: 1 }}
            />
            <CopyButton text={inviteUrl}/>
          </Box>
        </>
      }
      </Box>
    </Modal>
  )
}

const EnhancedTableToolbar = (props) => {
  const { selected, setSelected } = props;
  const numSelected = selected.length;
  const [open, setOpen] = useState(false);
  const handlePersonAdd = () => setOpen(true);
  const { req } = useApi();
  const { course, fetchCourse } = useCourseDetail();
  const { enqueueSnackbar } = useSnackbar();

  const handlePersonRemove = async () => {
    await Promise.all(
      selected.map(
        _id => req("delete", `/api/course/${course.shortname}/student/${_id}`)
      )
    ).then( res => {
      enqueueSnackbar('刪除成功', { variant: 'success' })
      fetchCourse();
      setSelected([]);
    })
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          已選擇 {numSelected} 個
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        />
      )}
      {numSelected > 0 ? (
        <Tooltip title="刪除" placement="top">
          <IconButton onClick={handlePersonRemove}>
            <PersonRemoveIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="邀請" placement="top">
          <IconButton onClick={handlePersonAdd}>
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
      )}
      { open && <InviteModal open={open} setOpen={setOpen} /> }
    </Toolbar>
  );
};

const StudentList = (props) => {
  const rows = props.students;
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 1
        }}
      >
        <Typography variant="h5">
          學生列表
        </Typography>
      </Box>
      <Divider />
      <Paper sx={{ width: '100%', mt: 2, mb: 2 }}>
        <EnhancedTableToolbar selected={selected} setSelected={setSelected} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </Box>
  );
}

const CourseStudents = () => {
  const { course } = useCourseDetail();
  const { auth } = useAuth();

  if (!auth.profile.isAdmin)
    return <Redirect to="/403" />
    
  return (
    <NavBar
      title="題單系統"
      value={CourseDetailNavBar(course.shortname, auth.profile.isAdmin)}
    >
      <Box sx={{ p: 1 }}>
        <Breadcrumbs value={[
          { label: '題單系統', link: '/' },
          { label: '課程題單', link: '/course' },
          { label: `${course.name}`, link: `/course/${course.shortname}` },
          { label: '學生列表' }
        ]}/>
        <Box sx={{
          p: 2,
          m: '0 auto',
          mt: 3,
          width: '70%',
        }}>
          <StudentList students={course.students} />
        </Box>
      </Box>
    </NavBar>
  );
}

export default function CourseStudentsPage(props) {
  const { shortname } = props.match.params;
  return (
    <CourseDetailProvider shortname={shortname} >
      <CourseStudents />
    </CourseDetailProvider>
  )
}