import { createContext, useContext, useEffect, useState } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";
import { useSnackbar } from "notistack";

const TaskListContext = createContext();

export const useTaskList = () => useContext(TaskListContext);

export default function ConceptTaskList(props) {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [ conceptTaskList, setConceptTaskList ] = useState(null);
  const [ programTaskList, setProgramTaskList ] = useState(null);
  const { req } = useApi();

  const { enqueueSnackbar } = useSnackbar();

  const fetchTask = () => {
    Promise.all([
      req("get", '/api/concept_task'),
      req("get", '/api/program_task')
    ]).then( (res) => {
      setConceptTaskList(res[0].data);
      setProgramTaskList(res[1].data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    fetchTask();
  }, [])

  const updateConceptTask = (task) => {
    req("put", `/api/concept_task/${task._id}`, task).then( res => {
      setConceptTaskList(conceptTaskList.map( task => task._id === res.data._id ? res.data : task ));
      enqueueSnackbar(`更新題目成功`, { variant: 'success' });
    })
  }

  const updateProgramTask = (task) => {
    req("put", `/api/program_task/${task._id}`, task).then( res => {
      setProgramTaskList(programTaskList.map( task => task._id === res.data._id ? res.data : task ));
      enqueueSnackbar(`更新題目成功`, { variant: 'success' });
    })
  }

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <TaskListContext.Provider value={{
      conceptTaskList,
      updateConceptTask,
      programTaskList,
      updateProgramTask
    }}>
      { children }
    </TaskListContext.Provider>
  );
}