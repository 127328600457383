import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TaskListProvider, { useTaskList } from 'contexts/Task/List';

import { CourseListNavBar } from '../NavBar';
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import Pagination from '@mui/material/Pagination';
import mathjax from "utilities/mathjax";

import { useAuth } from 'contexts/global/Auth';

import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Editor from 'components/Editor';
import Link from '@mui/material/Link';

import Switch from '@mui/material/Switch';

const EditConceptTask = ({ task: _task, setEditMode }) => {
  const [task, setTask] = useState(_task);
  const { updateConceptTask } = useTaskList();

  useEffect(() => {
    setTask(_task);
  }, [_task])

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        <TextField
          sx={{ mr: 2, width: '100px' }}
          value={task.pid}
          onChange={ (e) => setTask((prev) => 
            { return { ...prev, pid: e.target.value } }
          )}
          label="pid"
          variant="standard"
          size="small"
        />
        <TextField
          sx={{ width: '300px'}}
          value={task.title}
          onChange={ (e) => setTask((prev) => 
            { return { ...prev, title: e.target.value } }
          )}
          label="title"
          variant="standard"
          size="small"
        />
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={ () => setEditMode(false) }>
          <CloseIcon fontSize="small"/>
        </IconButton>
        <IconButton onClick={ () => {
          updateConceptTask(task);
          setEditMode(false);
        }}>
          <DoneIcon fontSize="small"/>
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ height: '400px', mt: 1 }}>
        <Editor content={task.content} setContent={
          (content) => setTask((prev) => {
            return { ...prev, content };
          })}
        />
      </Box>
      <Divider />
      <Box sx={{ height: '400px', mt: 1 }}>
        <Editor content={task.answer} setContent={
          (answer) => setTask((prev) => {
            return { ...prev, answer };
          })}
        />
      </Box>
    </>
  )
}

const ViewConceptTask = ({ task, setEditMode }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">
          {task.pid}{task.title ? ` - ${task.title}` : ''}
        </Typography>
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={ () => setEditMode(true) }>
          <EditIcon fontSize="small"/>
        </IconButton>
      </Box>
      <Divider />
      {mathjax(task.content)}
      <Divider />
      {task.answer ? mathjax(task.answer) : 
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>沒有題解</Box>
      }
    </Box>
  )
}

const ConceptTask = ({ task }) => {
  const [editMode, setEditMode] = useState(false);
  useEffect(() => setEditMode(false), [task])
  return (
    <Card sx={{ mt: 2, p: 2 }} raised>
    { editMode ? 
      <EditConceptTask
        task={task}
        setEditMode={setEditMode}
      /> : 
      <ViewConceptTask 
        task={task}
        setEditMode={setEditMode}
      /> 
    }
    </Card>
  );
}

const EditProgramTask = ({ task: _task, setEditMode }) => {
  const [task, setTask] = useState(_task);
  const { updateProgramTask } = useTaskList();

  useEffect(() => {
    setTask(_task);
  }, [_task])

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        <TextField
          sx={{ mr: 2, width: '300px' }}
          value={task.url}
          onChange={ (e) => setTask((prev) => 
            { return { ...prev, url: e.target.value } }
          )}
          label="url"
          variant="standard"
          size="small"
        />
        <TextField
          sx={{ width: '300px'}}
          value={task.title}
          onChange={ (e) => setTask((prev) => 
            { return { ...prev, title: e.target.value } }
          )}
          label="title"
          variant="standard"
          size="small"
        />
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={ () => setEditMode(false) }>
          <CloseIcon fontSize="small"/>
        </IconButton>
        <IconButton onClick={ () => {
          updateProgramTask(task);
          setEditMode(false);
        }}>
          <DoneIcon fontSize="small"/>
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ height: '400px', mt: 1 }}>
        <Editor content={task.answer} setContent={
          (answer) => setTask((prev) => {
            return { ...prev, answer };
          })}
        />
      </Box>
    </>
  )
}

const ViewProgramTask = ({ task, setEditMode }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">
          {task.title}
        </Typography>
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={ () => setEditMode(true) }>
          <EditIcon fontSize="small"/>
        </IconButton>
      </Box>
      <Divider />
      {task.answer ? mathjax(task.answer) : 
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>沒有題解</Box>
      }
    </Box>
  )
}

const ProgramTask = ({ task }) => {
  const [editMode, setEditMode] = useState(false);
  useEffect(() => setEditMode(false), [task])
  return (
    <Card sx={{ mt: 2, p: 2 }} raised>
    { editMode ? 
      <EditProgramTask
        task={task}
        setEditMode={setEditMode}
      /> : 
      <ViewProgramTask 
        task={task}
        setEditMode={setEditMode}
      /> 
    }
    </Card>
  );

}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const TaskList = () => {
  const { conceptTaskList, programTaskList } = useTaskList();
  const perPage = 5;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isConcept, setIsConcept] = useState(true);
  
  const taskList = isConcept ? conceptTaskList : programTaskList;
  const filtered_task = taskList.filter( task => (isConcept ? task.pid : task.title).match(new RegExp(escapeRegExp(search))) )
  const sliced_task = 
    filtered_task.slice(
      perPage * (page - 1),
      Math.min(
        filtered_task.length,
        perPage * page
      )
    );

  return (
    <Box sx={{ width: '80%', minWidth: '700px', m: '0 auto', mt: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          variant="standard"
          value={search}
          onChange={ (e) => {
            setSearch(e.target.value)
            setPage(1)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Typography>程式題</Typography>
        <Switch  checked={isConcept} onChange={ (e) => {
          setIsConcept(e.target.checked) 
          setPage(1)
        }} />
        <Typography>觀念題</Typography>
        <Box sx={{ flex: 1 }} />
        <Pagination 
          page={page}
          onChange={(e, v) => setPage(v)}
          count={Math.ceil(filtered_task.length / perPage)} />
      </Box>
      { sliced_task.length ? 
        sliced_task.map( (task, idx) => isConcept ? <ConceptTask key={idx} task={task} /> : <ProgramTask key={idx} task={task} /> ) :
        <Box sx={{ display: 'flex', justifyContent: 'center' }} >沒有任何符合的搜尋結果, 請嘗試
          <Link
            onClick={
              () => { setSearch(''); setPage(1) }
            }
            color="inherit"
          >
            清除搜尋欄位
          </Link>
        </Box>
      }
    </Box>
  )
}

export default function TaskListPage() {
  const { auth } = useAuth();
  return (
    <TaskListProvider>
      <NavBar
        title="題單系統"
        value={
          CourseListNavBar(auth.profile.isAdmin)
        }
      >
        <Box sx={{ p: 1 }}>
          <Breadcrumbs value={[
            { label: '題單系統', link: '/' },
            { label: '題目管理' }
          ]}/>
          <TaskList />
        </Box>
      </NavBar>
    </TaskListProvider>
  )
}