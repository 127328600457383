import MathJax from 'react-mathjax3'
import * as React from 'react';

export default function mathjax(html) {
  return (
    <MathJax.Context
        input='tex'
        onLoad={ () => console.log("Loaded MathJax script!") }
        onError={ (MathJax, error) => {
            console.warn(error);
            console.log("Encountered a MathJax error, re-attempting a typeset!");
            MathJax.Hub.Queue(
              MathJax.Hub.Typeset()
            );
        } }
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js"
        options={ {
            messageStyle: 'none',
            extensions: ['tex2jax.js'],
            jax: ['input/TeX', 'output/HTML-CSS'],
            tex2jax: {
                inlineMath: [['$', '$'], ['\\(', '\\)']],
                displayMath: [['$$', '$$'], ['\\[', '\\]']],
                processEscapes: true,
            },
            TeX: {
                extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js']
            }
        } }
    >
        <MathJax.Html html={ html } />
    </MathJax.Context>
);
}