import { NavBarItem } from './index';
import LogoutIcon from '@mui/icons-material/Logout';
import { base64Encode } from 'utilities/base64';

export default function Logout() {
  return (
    <NavBarItem
      icon={<LogoutIcon />}
      label='登出'
      href={
        `/api/auth/logout?redirect=${base64Encode(window.location.href)}`
      }
    />
  );
}