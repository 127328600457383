import { createContext, useContext, useEffect, useState } from "react"
import { useApi } from "./Api";
import Loading from "../../components/Loading";
import Box from '@mui/material/Box';

import { base64Encode } from 'utilities/base64'; 

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function Auth({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    profile: null
  })

  const { req } = useApi();
  useEffect(() => {
    req("get", "/api/auth/profile").then( res => {
      setAuth({
        isAuthenticated: true,
        profile: res.data
      });
      setIsLoading(false)
    }, () => {
      const { href } = window.location;
      window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/api/auth/login?redirect=${base64Encode(href)}`)
    })
  }, [])

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <AuthContext.Provider value={{ auth }}>
      { children }
    </AuthContext.Provider>
  )
}