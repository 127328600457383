import Box from '@mui/material/Box';
import ClassIcon from '@mui/icons-material/Class';
import Divider from '@mui/material/Divider';

import Typography from "@mui/material/Typography"
import Breadcrumbs from 'components/Breadcrumbs';
import NavBar from 'components/NavBar';
import CourseListProvider, { useCourseList } from 'contexts/Course/List';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import { useAuth } from 'contexts/global/Auth';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import { CourseListNavBar } from '../NavBar';

const CourseCardAction = ({ course }) => {
  return (
    <CardActions
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
     >
      <Button
        startIcon={<PeopleIcon />}
        color="inherit"
        href={`/course/${course.shortname}/students`}
      >
        { course.students.length }
      </Button>
      <IconButton
        size="small"
        href={`/course/${course.shortname}/settings`}
        color="inherit"
      >
        <SettingsIcon />
      </IconButton>
    </CardActions>
  );
}

const CourseCard = ({ course }) => {
  const { auth } = useAuth();
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        height: 320,
        m: 1
      }}
      raised
    >
      <CardActionArea
        href={`/course/${course.shortname}`}
        sx={{ flexGrow: 1 }}
      >
        <CardMedia
          component="img"
          height={180}
          image={course.image}
        />
        <Divider />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {course.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      { auth.profile.isAdmin && <CourseCardAction course={course} /> }
    </Card>
  );
}

const AddCourseCard = () => {
  const { auth } = useAuth();

  return (
    <Card
      sx={{
        width: 300,
        height: 320,
        m: 1,
      }}
      raised
    >
      <CardActionArea
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        href="/new_course"
      >
        <AddIcon fontSize="large" />
        <Typography variant="h6">
        { auth.profile.isAdmin ? "新增課程" : "加入課程" }
        </Typography>
      </CardActionArea>
    </Card>
  )
}

const CourseList = () => {
  const { courseList } = useCourseList();
  const currCourseList = courseList.filter( course => !course.history );
  const prevCourseList = courseList.filter( course =>  course.history );
  return (
    <Box sx={{ p: 2 }}>
      {currCourseList.length !== 0 && <>
        <Typography variant="h5">
          當期課程
        </Typography>
        <Divider />
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            currCourseList
              .map( (course, idx) => <CourseCard key={idx} course={course} />)
          }
          <AddCourseCard />
        </Box>
      </>}
      {prevCourseList.length !== 0 && <>
        <Typography variant="h5" sx={{ mt: 4}}>
          歷史課程
        </Typography>
        <Divider />
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            prevCourseList
              .map( (course, idx) => <CourseCard key={idx} course={course} />)
          }
        </Box>
      </>}
    </Box>
  );
}

export default function CourseListPage() {
  const { auth } = useAuth();
  return (
    <CourseListProvider>
      <NavBar
        title="題單系統"
        value={
          CourseListNavBar(auth.profile.isAdmin)
        }
      >
        <Box sx={{ p: 1 }}>
          <Breadcrumbs value={[
            { label: '題單系統', link: '/' },
            { label: '課程題單' }
          ]}/>
          <CourseList />
        </Box>
      </NavBar>
    </CourseListProvider>
  )
}