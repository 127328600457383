import { NavBarItem } from 'components/NavBar/NavBarItem/index';
import NavBarLogout from 'components/NavBar/NavBarItem/Logout';
import Divider from '@mui/material/Divider';

import AssignmentIcon from '@mui/icons-material/Assignment';
import ClassIcon from '@mui/icons-material/Class';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AdbIcon from '@mui/icons-material/Adb';
import TaskIcon from '@mui/icons-material/Task';

export const CourseListNavBar = (isAdmin) => [
  <NavBarItem
    icon={<ClassIcon />}
    label='課程題單'
    href={`/course`}
  />,
  ...(isAdmin ? [
    <NavBarItem
      icon={<TaskIcon />}
      label='題目管理'
      href={`/task`}
    />
  ] : []),
  <Divider />,
  <NavBarLogout />,
];

export const CourseDetailNavBar = (shortname, isAdmin) => [
  <NavBarItem
    icon={<AssignmentIcon />}
    label='題單列表'
    href={`/course/${shortname}`}
  />,
  ...(isAdmin ? [
    <NavBarItem
      icon={<RateReviewIcon />}
      label='作業批改'
      href={`/course/${shortname}/feedback`}
    />,
    <NavBarItem
      icon={<LeaderboardIcon />}
      label='分數統計'
      href={`/course/${shortname}/scoreboard`}
    />,
    <Divider />,
    <NavBarItem
      icon={<SettingsIcon />}
      label='一般設定'
      href={`/course/${shortname}/settings`}
    />,
    <NavBarItem
      icon={<PeopleIcon />}
      label='學生列表'
      href={`/course/${shortname}/students`}
    />,
  ] : []),
  <Divider />,
  <NavBarLogout />,
];