import { createContext, useEffect, useContext, useState, useCallback } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";
import { useSnackbar } from "notistack";

const ConceptContext = createContext();

export const useConcept = () => useContext(ConceptContext);

export default function Concept(props) {
  const { children, concept_id } = props;
  const { req } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [concept, setConcept] = useState(null);
  const [conceptSubmissions, setConceptSubmissoins] = useState([]);
  const [content, setContent] = useState('');
  const [viewSubmissionId, setViewSubmissionId] = useState(null);
  const [viewContent, setViewContent] = useState('');

  const fetchConcept = useCallback((payload) => {
    req("get", `/api/concept_task_score/${concept_id}`, payload).then( res => {
      setConcept(res.data.task);
      setIsLoading(false);
    }, () => {
      window.location.assign('/403');
    })
  }, [req, concept_id])

  const fetchConceptSubmissions = useCallback(() => {
    req("get", `/api/concept_task_submission/?task=${concept_id}`).then( res => {
      setConceptSubmissoins(res.data);
    })
  }, [req, concept_id])

  useEffect(() => {
    fetchConcept()
    fetchConceptSubmissions();
  }, [fetchConcept, fetchConceptSubmissions])

  const { enqueueSnackbar } = useSnackbar();

  const unlockAnswer = () => {
    req("post", `/api/concept_task_answer_unlock`, { task: concept_id }).then( res => {
      enqueueSnackbar(`題目解答已解鎖`, { variant: 'success' });
      fetchConcept();
    })
  }

  const createConceptSubmission = (payload) => {
    if (payload.content.length == 0) {
      enqueueSnackbar(`請填寫內容`, { variant: 'error' });
      return ;
    }

    req("post", `/api/concept_task_score/${concept_id}/submission`, payload).then( res => {
      fetchConceptSubmissions();
      setContent('');
      enqueueSnackbar(`新增提交成功`, { variant: 'success' });
    })
  }

  const deleteSubmission = (payload) => {
    req("delete", `/api/concept_task_submission/${payload}`).then(res => {
      fetchConceptSubmissions();
      enqueueSnackbar(`刪除提交成功`, { variant: 'success' });
      if (payload === viewSubmissionId)
        resetViewSubmissionId();
    })
  }

  const updateConceptSubmission = (payload) => {
    req("put", `/api/concept_task_submission/${viewSubmissionId}`, payload).then(res => {
      enqueueSnackbar(`更新提交成功`, { variant: 'success' });
    });
  }

  const changeViewSubmissionId = (payload) => {
    setViewSubmissionId(payload);
    req("get", `/api/concept_task_submission/${payload}`).then(res => {
      console.log(res.data);
      setViewContent(res.data.content)
    })
  }

  const resetViewSubmissionId = () => {
    setViewSubmissionId(null);
    setViewContent('');
  }

  /*
  const updateCourse = (payload) => {
    req("put", `/api/course/${course.shortname}`, payload).then( res => {
      const { name, shortname, image } = res.data;
      setHowework((prev) => { return {
        ...prev,
        name,
        shortname,
        image
      }});
      enqueueSnackbar(`更新成功`, { variant: 'success' });
    })
  }

  const deleteCourse = () => {
    req("delete", `/api/course/${course.shortname}`).then( res => {
      enqueueSnackbar(`刪除課程成功`, { variant: 'success' });
      window.location.assign('/course');
    })
  }

  const createSection = () => {
    req("post", `/api/course/${course.shortname}/section`, {}).then( res => {
      fetchCourse()
    })
  }

  const deleteSection = (_id) => {
    req("delete", `/api/course/${course.shortname}/section/${_id}`).then( res => {
      enqueueSnackbar(`刪除區塊成功`, { variant: 'success' });
      fetchCourse();
    })
  }

  const updateSection = (payload) => {
    req("put", `/api/section/${payload._id}`, payload).then( res => {
    })
  }

  const importSectionTasks = (payload) => {
    const { content } = payload;
    req("put", `/api/section/${payload.section_id}/csv`, { content })
    .then( res => {
      enqueueSnackbar('匯入 csv 成功', { variant: 'success' })
      fetchCourse();
    })
  }

  const submit = (payload) => {
    req("post", `/api/submission`, payload)
    .then( () => {
      enqueueSnackbar('更改狀態成功', { variant: 'success' })
      fetchCourse();
    })
  }

  const reorder = (from, to) => {
    if (Math.min(from, to) < 0 || Math.max(from, to) >= course.sections.length || from == to)
      return ;
    
    let sections = course.sections.map( section => section._id );
    const [remove] = sections.splice(from, 1);
    sections.splice(to, 0, remove);
      
    req("put", `/api/course/${course.shortname}/section`, { sections })
    .then( () => {
      enqueueSnackbar('替換順序成功', { variant: 'success' })
      fetchCourse();
    })
  }
  */

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <ConceptContext.Provider value={{
      concept,
      conceptSubmissions,
      createConceptSubmission,
      content,
      setContent,
      deleteSubmission,
      changeViewSubmissionId,
      resetViewSubmissionId,
      viewSubmissionId,
      viewContent,
      setViewContent,
      updateConceptSubmission,
      unlockAnswer
    }}>
      { children }
    </ConceptContext.Provider>
  );
}