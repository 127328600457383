import { createContext, useEffect, useContext, useState, useCallback } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";
import { useSnackbar } from "notistack";

const CourseScoreboardContext = createContext();

export const useCourseScoreboard = () => useContext(CourseScoreboardContext);

export default function CourseScoreboard(props) {
  const { children, shortname } = props;
  const { req } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [scoreboard, setScoreboard] = useState([]);

  const fetchScoreboard = useCallback((payload) => {
    req("get", `/api/course/${shortname}/scoreboard`, payload).then( res => {
      setScoreboard(res.data);
      setIsLoading(false);
    }, () => {
      // window.location.assign('/403');
    })
  }, [req, shortname])

  useEffect(() => {
    fetchScoreboard()
  }, [fetchScoreboard])

  const { enqueueSnackbar } = useSnackbar();

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <CourseScoreboardContext.Provider value={{
      scoreboard
    }}>
      { children }
    </CourseScoreboardContext.Provider>
  );
}