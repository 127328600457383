import { createContext, useEffect, useContext, useState, useCallback } from "react";
import Loading from "components/Loading";
import Box from '@mui/material/Box';
import { useApi } from "contexts/global/Api";
import { useSnackbar } from "notistack";

const ProgramContext = createContext();

export const useProgram = () => useContext(ProgramContext);

export default function Program(props) {
  const { children, program_id } = props;
  const { req } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [program, setProgram] = useState(null);
  const [programSubmissions, setProgramSubmissoins] = useState([]);
  const [viewSubmissionId, setViewSubmissionId] = useState(null);

  const EMPTY_CONTENT = { code: '', status: '---' };
  const [content, setContent] = useState(EMPTY_CONTENT);
  const [viewContent, setViewContent] = useState(EMPTY_CONTENT);

  const fetchProgram = useCallback((payload) => {
    req("get", `/api/program_task_score/${program_id}`, payload).then( res => {
      setProgram(res.data.task);
      setIsLoading(false);
    }, () => {
      // window.location.assign('/403');
    })
  }, [req, program_id])

  const fetchProgramSubmissions = useCallback(() => {
    req("get", `/api/program_task_submission/?task=${program_id}`).then( res => {
      setProgramSubmissoins(res.data);
    })
  }, [req, program_id])

  useEffect(() => {
    fetchProgram()
    fetchProgramSubmissions();
  }, [fetchProgram, fetchProgramSubmissions])

  const { enqueueSnackbar } = useSnackbar();
  const createProgramSubmission = (payload) => {
    if (payload.content.code.length == 0) {
      enqueueSnackbar(`請填寫程式碼`, { variant: 'error' });
      return ;
    }
    if (payload.content.status === '---') {
      enqueueSnackbar(`請填寫結果`, { variant: 'error' });
      return ;
    }

    req("post", `/api/program_task_score/${program_id}/submission`, payload.content).then( res => {
      fetchProgramSubmissions();
      setContent(EMPTY_CONTENT);
      enqueueSnackbar(`新增提交成功`, { variant: 'success' });
    })
  }

  const deleteSubmission = (payload) => {
    req("delete", `/api/program_task_submission/${payload}`).then(res => {
      fetchProgramSubmissions();
      enqueueSnackbar(`刪除提交成功`, { variant: 'success' });
      if (payload === viewSubmissionId)
        resetViewSubmissionId();
    })
  }

  const updateProgramSubmission = (payload) => {
    req("put", `/api/program_task_submission/${viewSubmissionId}`, payload.content).then(res => {
      enqueueSnackbar(`更新提交成功`, { variant: 'success' });
    });
  }

  const changeViewSubmissionId = (payload) => {
    setViewSubmissionId(payload);
    req("get", `/api/program_task_submission/${payload}`).then(res => {
      setViewContent(res.data)
    })
  }

  const resetViewSubmissionId = () => {
    setViewSubmissionId(null);
    setViewContent(EMPTY_CONTENT);
  }

  const unlockAnswer = () => {
    req("post", `/api/program_task_answer_unlock`, { task: program_id }).then( res => {
      enqueueSnackbar(`題目解答已解鎖`, { variant: 'success' });
      fetchProgram();
    })
  }

  /*
  const updateCourse = (payload) => {
    req("put", `/api/course/${course.shortname}`, payload).then( res => {
      const { name, shortname, image } = res.data;
      setHowework((prev) => { return {
        ...prev,
        name,
        shortname,
        image
      }});
      enqueueSnackbar(`更新成功`, { variant: 'success' });
    })
  }

  const deleteCourse = () => {
    req("delete", `/api/course/${course.shortname}`).then( res => {
      enqueueSnackbar(`刪除課程成功`, { variant: 'success' });
      window.location.assign('/course');
    })
  }

  const createSection = () => {
    req("post", `/api/course/${course.shortname}/section`, {}).then( res => {
      fetchCourse()
    })
  }

  const deleteSection = (_id) => {
    req("delete", `/api/course/${course.shortname}/section/${_id}`).then( res => {
      enqueueSnackbar(`刪除區塊成功`, { variant: 'success' });
      fetchCourse();
    })
  }

  const updateSection = (payload) => {
    req("put", `/api/section/${payload._id}`, payload).then( res => {
    })
  }

  const importSectionTasks = (payload) => {
    const { content } = payload;
    req("put", `/api/section/${payload.section_id}/csv`, { content })
    .then( res => {
      enqueueSnackbar('匯入 csv 成功', { variant: 'success' })
      fetchCourse();
    })
  }

  const submit = (payload) => {
    req("post", `/api/submission`, payload)
    .then( () => {
      enqueueSnackbar('更改狀態成功', { variant: 'success' })
      fetchCourse();
    })
  }

  const reorder = (from, to) => {
    if (Math.min(from, to) < 0 || Math.max(from, to) >= course.sections.length || from == to)
      return ;
    
    let sections = course.sections.map( section => section._id );
    const [remove] = sections.splice(from, 1);
    sections.splice(to, 0, remove);
      
    req("put", `/api/course/${course.shortname}/section`, { sections })
    .then( () => {
      enqueueSnackbar('替換順序成功', { variant: 'success' })
      fetchCourse();
    })
  }
  */

  if (isLoading)
    return (
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Loading />
      </Box>
    )

  return (
    <ProgramContext.Provider value={{
      program,
      programSubmissions,
      createProgramSubmission,
      content,
      setContent,
      deleteSubmission,
      changeViewSubmissionId,
      resetViewSubmissionId,
      viewSubmissionId,
      viewContent,
      setViewContent,
      updateProgramSubmission,
      unlockAnswer
    }}>
      { children }
    </ProgramContext.Provider>
  );
}